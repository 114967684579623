import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-corporate-vendor-driver',
  templateUrl: './corporate-vendor-driver.component.html',
  styleUrls: ['./corporate-vendor-driver.component.css']
})
export class CorporateVendorDriverComponent implements OnInit {
  public spinkit = Spinkit;
  _corporatedriverData:any;
  image_path:string;
  constructor(
    private _CorporateVendorService:CorporateVendorService,
    private _ActivatedRoute:ActivatedRoute,
    private _Router:Router,    
  ) { }

  ngOnInit() {
    const corporate_driver_id= this._ActivatedRoute.snapshot.params.id;    
    this._CorporateVendorService.getCorporateDriver(corporate_driver_id).subscribe(dat=>{      
      this._corporatedriverData=dat['data']['corporate_driver'];
      this.image_path=dat['data']['image_path']; 
    },
    error => {        
      console.log(error);     
    });
  }

}

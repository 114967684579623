import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';

import { AngularFireAuth } from  "@angular/fire/auth";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(
    public _AuthenticationService:AuthenticationService,
    private _router:Router,
    private _DataService:DataService,
    public  afAuth:  AngularFireAuth, 
    ) {}

  ngOnInit() {
  }
  logout() {
    this._DataService._setSearchData('');
    this._AuthenticationService.isLogedOut;
    this._AuthenticationService.isNotRole;   
    this._AuthenticationService.isNotFireBaseUID;  
    console.log( this._AuthenticationService.isLogedOut);
    console.log( this._AuthenticationService.isNotRole)  ;
    console.log( this._AuthenticationService.isNotFireBaseUID);       
    this.afAuth.auth.signOut();     
    this._router.navigate(['/login']);
  } 
}

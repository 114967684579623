import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-assign-driver-vehicles',
  templateUrl: './corporate-vendor-assign-driver-vehicles.component.html',
  styleUrls: ['./corporate-vendor-assign-driver-vehicles.component.css']
})
export class CorporateVendorAssignDriverVehiclesComponent implements OnInit {

  public spinkit = Spinkit;
  _editVehicleProfileForm :FormGroup;
  _submitted = false;
  Vehicles:any;
  Drivers:any;
  AllDrivers:any;
  _status:number;
  _msg:string;
  minDate: Date;
  cdriver_id:number;
  hideAssignDriver:boolean=true;
  AllDriversLength:number;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,
  ) { 
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
  }


  ngOnInit() {  
    this._editVehicleProfileForm = this._formBuilder.group({
      vehicle_id: ['', Validators.required],
      corporate_driver_id: ['', Validators.required],
      last_assign_date: ['', Validators.required],      
    });   
    this._CorporateVendorService.getUnassignDriverVehicle().subscribe(res=>{
      this.Vehicles=res['data']['vehicles'];
      this.Drivers=res['data']['drivers'];
      this.AllDrivers=res['data']['alldrivers'];      
      this.AllDriversLength=res['data']['alldrivers'].length;
    },error => {        
      console.log(error);     
    });
  }
  get f() { return this._editVehicleProfileForm.controls; }
  onSubmit(){    
    this._submitted = true;      
     if(this._editVehicleProfileForm.invalid){
       return false;
     }      
     this._CorporateVendorService.assignDriverVehicle(this._editVehicleProfileForm.value).subscribe(res=>{     
       this._status=res['status'];  
       this._msg=res['message'];      
       if(this._status==1){
         this._Router.navigate(['/corp-vendor/']);  
       } 
     },error => {        
       console.log(error);  
       this._status=0;
       this._msg='Unable to add Driver. Because of Internal Server Error,Try again Later';
     }); 
   }
   DrverNameSelect(corporate_driver_id){
     this.hideAssignDriver=false;
      this.cdriver_id=corporate_driver_id;
   }

}
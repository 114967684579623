import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { DriverTripService } from 'src/app/_services/driver-trip.service';
@Component({
  selector: 'app-corporate-vendor-assign-trip-driver',
  templateUrl: './corporate-vendor-assign-trip-driver.component.html',
  styleUrls: ['./corporate-vendor-assign-trip-driver.component.css']
})
export class CorporateVendorAssignTripDriverComponent implements OnInit {
  public spinkit = Spinkit;
  _createTripForm :FormGroup;
  _submitted = false;
  routeData=[];
  _status:number;
  _msg:string;  
  buttonDisabled:boolean=true;
  routeDataLength:number;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _DriverTripService:DriverTripService,
    private _Router:Router,
  ) { }
  ngOnInit() {
     this._createTripForm=this._formBuilder.group({
      route_id:[''], 
      trip_id:['',Validators.required],     
    });  
    
    this._CorporateVendorService.getTripRouts().subscribe(res=>{
      this.routeData=res['data'];
      this.routeDataLength=res['data'].length;
    },error => {        
      console.log(error);     
    });
  }
  showTripDiv:boolean=false;
  area_route:any;    
  trips:any;
  routeChanged(evt){  
    this.buttonDisabled=true; 
    this.showTripDiv=true; 
    let route_id=evt.target.value;     
    this._DriverTripService.getCorporateTrip(route_id).subscribe(res=>{     
      this.area_route=res['data']['area_route'];     
      this.trips=res['data']['trips'];  
      if(this.trips.length>0){
        this.buttonDisabled=false;
      }     
    });  
  }  
  get f() { return this._createTripForm.controls; }  
  onSubmit(){    
    this._submitted = true;    
    if (this._createTripForm.invalid) {
      return;
    }  
    this._DriverTripService.assignCorporateTrip(this._createTripForm.value).subscribe(res=>{      
      this._status=res['status'];
      this._msg=res['message'];       
      this._createTripForm.reset();
      this.showTripDiv=false; 
    },
    error => {        
      console.log(error);
      this._status=0;
      this._msg='Unable to submit. Because of Internal Server Error,Try again Later';      
    });   
  } 
} 
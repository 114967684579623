import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserValidationService {

  constructor() { }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode!=43 && charCode < 48 )|| charCode > 57)) {
      return false;
    }
    return true;
  }
  stringOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || (charCode > 90 && charCode < 97 ) || charCode >122 )) {
      return false;
    }
    return true;
  }
  
  amountNumberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && ((charCode!=46 && charCode!=43 && charCode < 48 )|| charCode > 57)) {
      return false;
    }
    return true;
  }

  timeFormate(timeString){    
    if(timeString){
      var H = +timeString.substr(0, 2);
      var h = (H % 12) || 12;
      var ampm = H < 12 ? " AM" : " PM";
      timeString = h + timeString.substr(2, 3) + ampm;
    }   
    return timeString;
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Spinkit } from 'ng-http-loader';
import { DataService } from '../../_services/data.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  public spinkit = Spinkit;
  agentRequest=false;
  message='';
  constructor(
      private _DataService:DataService,
      private _Router:Router,
      public _AuthenticationService:AuthenticationService,
    ) { }
  customSilders: OwlOptions = {
    loop:true, dots: false, nav:true, navText:['', ''], items:1, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items: 1}, 400:{items: 1}, 740:{items: 1},940:{items: 1} }
  }
  customTestimonials: OwlOptions = {    
    loop:true, dots: false, nav:true, navText:['', ''], items:2, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:2}, 992:{items:2}, 1199:{items:2} }
  }
  ngOnInit() {
    this._DataService.unSetUserTypeRole(); 
    this._DataService.unsetUsergent();       
    if(this._AuthenticationService.userId>0){     
      let user_id=this._AuthenticationService.userId;
      let datat=this._DataService.isAgent(user_id).subscribe(res => {           
        if(res['data']['user_id']){
          this.agentRequest=true;
        }
      });
    }     
  }
  wantAgent(type,agent){           
    this._DataService.setUserTypeRole(type);   
    this._DataService.setUsergent(agent);   
    this._Router.navigate(['/register']);   
  }
  AgentRequest(){
    let datat=this._DataService.AgentRequest().subscribe(res => {         
      if(res['status']==1){
        this.agentRequest=false;
      }
      this.message=res['message'];
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import { DataService } from '../../_services/data.service';
import { RegistrationService } from 'src/app/_services/registration.service';

import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public spinkit = Spinkit;
  _registreForm :FormGroup;
  _submitted = false;
  __showControls = 'C';
  _roles=new Array;
  _status:number;
  _msg:string;
  emailNotEmpty:string;
  nameNotEmpty:string; 
  phoneNotEmpty:string;
  passwordNotEmpty:string;
  confirmPasswordNotEmpty:string;
  agree:string;
  reqMsg:boolean=false;
  tRole:string='';
  _getUsergent:string="";
  showPass:boolean=false;
  showConfPass:boolean=false;
  constructor(
    private _formBuilder: FormBuilder,       
    private _data:DataService,
    private _RegistrationService:RegistrationService,
    private _router:Router,
    public _UserValidation:UserValidationService,
  ) {}
  ngOnInit() {    
    this._getUsergent=this._data.getUsergent(); 
    //console.log('========>',this._getUsergent);
    this.tRole=this._data.getUserTypeRole();     
    this._registreForm = this._formBuilder.group({
      name: ['', [ Validators.required,Validators.pattern]],
      phone_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(12)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8),Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[@#$%^&+*!=]).{8,30}$/)]],
      confirmPassword: ['', Validators.required],     
      user_type: ['C', Validators.required], 
      agree: [false, Validators.requiredTrue]  
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
   
    this._data._getRoles().subscribe(res => {      
      this._roles = res['data'];      
    });
  }  
  showControls(showControls) {    
    this.__showControls = showControls;
  }
  get f() { return this._registreForm.controls; }
  onSubmit() {    
    this._submitted = true;
    this.reqMsg=true;   
    if (this._registreForm.invalid) {
      return;
    }      
    this._registreForm.value['want_agent']=this._data.getUsergent();     
    this._RegistrationService.register(this._registreForm.value).subscribe(
      res => {         
        this._status=res['status'];
        this._msg=res['message'];  
            
        if(this._status==1){ 
          this._data.unSetUserTypeRole(); 
          this._data.unsetUsergent();         
          this.tRole=this._data.getUserTypeRole();           
          if(this._registreForm.value['user_type']==='C' || this._registreForm.value['user_type']==='D'){
            this._registreForm.value['password']=res['pass'];           
            this._RegistrationService.doRegisterWithFireBase(this._registreForm.value);
            this._registreForm.value['password']='';     
          }           
          this.reqMsg=false;          
          this.emailNotEmpty='';
          this.confirmPasswordNotEmpty='';
          this.nameNotEmpty='';
          this.phoneNotEmpty='';
          this.passwordNotEmpty='';
          this.agree='';
          return false;
        }       
      },
      error => {        
        
        this._status=0;
        this._msg='Unable to Register. Because of Internal Server Error,Try again Later';                
      });
  }  
  ShowPassword(){   
    this.showPass=!this.showPass;
  }
  ShowConfPassword(){   
    this.showConfPass=!this.showConfPass;
  }
}
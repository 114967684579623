import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';

import { UrlApiService } from './url-api.service';
import { map } from 'rxjs/operators';

import { AngularFireAuth } from  "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    httpOptions:any;
    authenticated:boolean=false;
    authenticatedRole:string;
    user_id:number;
    fire_base_uid:string
    user_name:string;
    user_email:string;
    user_phone_number:string;  
    want_agent:string='';
  constructor(
    public  afAuth:  AngularFireAuth, 
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
      ) {
        this.httpOptions = {
            headers: new HttpHeaders(
              { 'Content-Type': 'application/json; charset=utf-8',     
              
              })
          }
       }
      get isLoggedIn(): boolean {
        
        return this.authenticated;
      }
      get isLogedOut(): boolean {  
        this.authenticated=false; 
        return this.authenticated;
       
      }
      get isRole(): string {       
        return this.authenticatedRole;
      }
      get userId(): number {       
        return this.user_id;
      }
      get FireBaseUID(): string {       
        return this.fire_base_uid;
      }
      get isNotFireBaseUID(): string {   
        this.fire_base_uid='';    
        return this.fire_base_uid;
      }
      get isNotRole(): string {   
        this.authenticatedRole='';    
        return this.authenticatedRole;
      }
      get userName(): string {            
        return this.user_name;
      }
      get userEmail(): string {            
        return this.user_email;
      }
      get userPhone(): string {            
        return this.user_phone_number;
      }
      get userWantAgent(): string {            
        return this.want_agent;
      }

  userLogin(email:string,password:string){
    let _url=this._urlApi._getUrl();
    let data={
        'username':email,
        'password':password,
    }
    return  this._Http.post(_url+'user-login',data,this.httpOptions)
    .pipe(map(user1 => {      
        
        if (user1 && user1['data']['userinfo']){
            this.authenticated=true;
            this.authenticatedRole=user1['data']['userinfo']['user_type'];  
            this.user_id=user1['data']['userinfo']['user_id'];    
            this.user_name=user1['data']['userinfo']['user_name'];   
            this.user_email=user1['data']['userinfo']['user_email'];  
            this.user_phone_number=user1['data']['userinfo']['user_phone_number'];  
            this.want_agent=user1['data']['userinfo']['want_agent'];                    
        }
        return user1;
    }));
  }
    
  userLoginSM(userPostData){    
    let _url=this._urlApi._getUrl();    
    return  this._Http.post(_url+'user-login-sm',userPostData,this.httpOptions)
    .pipe(map(user1 => {      
        
        if (user1 && user1['data']['userinfo']){
          this.authenticated=true;
          this.authenticatedRole=user1['data']['userinfo']['user_type'];  
          this.user_id=user1['data']['userinfo']['user_id'];    
          this.user_name=user1['data']['userinfo']['user_name'];  
          this.user_email=user1['data']['userinfo']['user_email'];  
          this.user_phone_number=user1['data']['userinfo']['user_phone_number'];  

        }
        return user1;
    }));
  } 
  loginWithfireBase(email,password){    
    this.afAuth.auth.signInWithEmailAndPassword(email,password).
      then((res) => {
        this.fire_base_uid=res.user.uid;      
      }).catch((error) => {
        console.log(error);
      })
  }

}
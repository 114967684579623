import { Component, OnInit } from '@angular/core';
import { CorporateUserService } from 'src/app/_services/corporate-user.service';
import { ActivatedRoute,Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';

@Component({
  selector: 'app-corporate-user-request-quote-details',
  templateUrl: './corporate-user-request-quote-details.component.html',
  styleUrls: ['./corporate-user-request-quote-details.component.css']
})
export class CorporateUserRequestQuoteDetailsComponent implements OnInit {

  public spinkit = Spinkit;
  _corporatequotationtData:any;
  _corporateRequestData:any;
  _corporatevehicle_typeData:any;
  _corporatevehicleData:any;
  _corporatedriverData:any;
  _corporatecompanyData:any;
  _corporateroutetData:any;
  _corporatetriptData:any;
  image_path:string;
  vehicle_path:string;
  company_logo_path:string;
  constructor(
    private _CorporateUserService:CorporateUserService,
    private _Router:Router,  
    private _ActivatedRoute:ActivatedRoute,
    public _UserValidation:UserValidationService,
    
    ) { }

  ngOnInit() {
   
    const corporate_request_id= this._ActivatedRoute.snapshot.params.id;  
    this._CorporateUserService.getCorporateUserBookinge(corporate_request_id).subscribe(dat=>{         
      this._corporateRequestData=dat['data']['corporate_request'];   
      this._corporatetriptData=dat['data']['trip'];   
      this._corporateroutetData=dat['data']['route'];   
      this._corporatecompanyData=dat['data']['company'];   
      this._corporatedriverData=dat['data']['driver'];   
      this._corporatevehicleData=dat['data']['vehicle'];    
      this._corporatevehicle_typeData=dat['data']['vehicle_type'];    
      this._corporatequotationtData=dat['data']['quotation'];  
      this.image_path=dat['data']['image_path'];  
      this.vehicle_path=dat['data']['vehicle_path'];  
      this.company_logo_path=dat['data']['company_logo_path'];     
     
    },
    error => {        
      console.log(error);     
    });
  }  

} 
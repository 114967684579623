import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from  '@angular/common/http';

import { map } from  'rxjs/operators';
import { UrlApiService } from '../_services/url-api.service';
@Injectable({
  providedIn: 'root'
})
export class UploadService { 
  constructor(
    private httpClient: HttpClient,
    private _urlApi:UrlApiService,
  
    ) { }
  public uploadFile(data) {
    let _url=this._urlApi._getUrl();   
    let uploadURL = _url+'web_upload_profile_avatar';
    return this.httpClient.post<any>(uploadURL, data);
  }
  public uploadDriverFile(data) {
    let _url=this._urlApi._getUrl();
    let uploadURL = _url+'web_upload_driver_profile_avatar';
    return this.httpClient.post<any>(uploadURL, data);
  }  
  public uploadVehicleFile(data) {
    let _url=this._urlApi._getUrl();
    let uploadURL = _url+'web_upload_vehicle_image';
    return this.httpClient.post<any>(uploadURL, data);
  }
  public uploadVehicleGalleryFile(data) {
    let _url=this._urlApi._getUrl();
    let uploadURL = _url+'web_upload_vehicle_gallery_image';
    return this.httpClient.post<any>(uploadURL, data);
  }
  uploadCorporateDriverFile(data){
    let _url=this._urlApi._getUrl();
    let uploadURL = _url+'web_upload_corporate_driver_avatar';
    return this.httpClient.post<any>(uploadURL, data);
  }  
  uploadCopanyLogoFile(data){
    let _url=this._urlApi._getUrl();
    let uploadURL = _url+'web_upload_company_logo_avatar';
    return this.httpClient.post<any>(uploadURL, data);
  }
  
}


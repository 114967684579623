import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';
import { Spinkit } from 'ng-http-loader';
// import { AuthService } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public spinkit = Spinkit;
  _loginForm:FormGroup;
  _submitted = false;
  _status:number;
  _msg:string;
  emailNotEmpty:string;
  pwdNotEmpty:string;
  showPass:boolean=false;
  constructor(
    private _FormBuilder:FormBuilder,
    private _AuthenticationService:AuthenticationService,
    private _Router:Router,
    private _DataService:DataService,
    // private authService: AuthService,   

  ) { 
    this._DataService.unSetUserTypeRole();
  }
  ngOnInit() {
    this._loginForm=this._FormBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      remember_me:[],
    });    
  }
  
  get f() { return this._loginForm.controls; }
  onSubmit() {      
    this._submitted = true;   
    if (this._loginForm.invalid) {
      return;
    }       
    this._AuthenticationService.userLogin(this.f.email.value,this.f.password.value).subscribe(
      res => {          
        if(res['status'] && res['data']['userinfo']['user_type']=='C' && res['data']['userinfo']['is_user']==1){
          let s_data=this._DataService._getSearchData();     
          this._AuthenticationService.loginWithfireBase(this.f.email.value,res['data']['userinfo']['firebase_pass']); 
          if(s_data){
            this._Router.navigate(['search']);  
            return;
          }
          this._Router.navigate(['/individual']);          
        }else if(res['status'] && res['data']['userinfo']['user_type']=='C' && res['data']['userinfo']['is_user']==0){
          this._AuthenticationService.loginWithfireBase(this.f.email.value,res['data']['userinfo']['firebase_pass']);
          this._Router.navigate(['user/edit-profile']);          
        }
        else if(res['status'] && res['data']['userinfo']['user_type']=='CV'){
          if(res['data']['userinfo']['is_user']==0){
            this._Router.navigate(['/corp-vendor/edit-profile']);     
          }else{
            this._Router.navigate(['/corp-vendor']);     
          }
         // this._Router.navigate(['/corp-vendor']);            
        }   
        else if(res['status'] && res['data']['userinfo']['user_type']=='CU'){
          if(res['data']['userinfo']['is_user']==0){
            this._Router.navigate(['/corp-user/edit-profile']);     
          }else{
            this._Router.navigate(['/corp-user']);  
          }
                  
        }   
        else if(res['status'] && res['data']['userinfo']['user_type']=='D' && res['data']['userinfo']['is_user']==1){
          this._AuthenticationService.loginWithfireBase(this.f.email.value,res['data']['userinfo']['firebase_pass']);
          this._Router.navigate(['/indv-vendor']);               
        }
        else if(res['status'] && res['data']['userinfo']['user_type']=='D' && res['data']['userinfo']['is_user']==0){
          this._AuthenticationService.loginWithfireBase(this.f.email.value,res['data']['userinfo']['firebase_pass']);
          this._Router.navigate(['indv-vendor/edit-profile']);
        }      
        else {
          this._AuthenticationService.isLogedOut;
          this._Router.navigate(['/login']);  
        }    
        this._status=res['status'];
        this._msg=res['message'];      
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Login. Because of Internal Server Error,Try again Later';                 
      });
  }    
  ShowPassword(){   
    this.showPass=!this.showPass;
  }
  // signInWithFB(): void {    
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {     
  //     this.apiConnection(userData);
  //    });
  // } 
  // signInWithGOOGLE(): void {    
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {    
  //     this.apiConnection(userData);
  //    });
  // } 
  // public userPostData = {
  //   email: '',
  //   name: '',
  //   provider: '',
  //   provider_id: '',
  //   auth_token: '',
   
  //   };
  // apiConnection(data) {
  //   this.userPostData.email = data.email;
  //   this.userPostData.name = data.name;
  //   this.userPostData.provider = data.provider;
  //   this.userPostData.provider_id = data.id;   
  //   this.userPostData.auth_token = data.authToken;    
  //   this._AuthenticationService.userLoginSM(this.userPostData).subscribe(
  //     res => {          
  //       if(res['status'] && res['data']['userinfo']['user_type']=='C' && res['data']['userinfo']['is_user']==1){          
  //         this._Router.navigate(['/individual']);
  //       }else if(res['status'] && res['data']['userinfo']['user_type']=='C' && res['data']['userinfo']['is_user']==0){
  //         this._Router.navigate(['user/edit-profile']);          
  //        }             
  //       else {
  //         this._AuthenticationService.isLogedOut;
  //         this._Router.navigate(['/login']);  
  //       }    
  //       this._status=res['status'];
  //       this._msg=res['message'];      
  //     },
  //     error => {        
  //       console.log(error);
  //       this._status=0;
  //       this._msg='Unable to Login. Because of Internal Server Error,Try again Later';       
  //     });
  // }  
  
}
import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-vendor-add-driver',
  templateUrl: './corporate-vendor-add-driver.component.html',
  styleUrls: ['./corporate-vendor-add-driver.component.css']
})
export class CorporateVendorAddDriverComponent implements OnInit {
  public spinkit = Spinkit;
  _editProfileForm :FormGroup; 
  _submitted = false;  
  _driverAvatar:any; 
 _wantAgentShow='n'; 
  _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  maxDate: Date;  
  civil:number;
  constructor(   
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,    
    public _UserValidation:UserValidationService, 
  ) { 
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);
  }

  ngOnInit() {    
    this._editProfileForm = this._formBuilder.group({
      driver_name: ['',[ Validators.required,Validators.pattern]],
      driver_email: ['', [Validators.required,Validators.email]],
      phone_number: ['+965',[ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      civil_id: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      gender: ['Male', Validators.required],
      dob: ['', Validators.required],
      whatsapp_number: ['+965',[ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      imo_number: ['+965',[ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      driver_nationality: ['', Validators.required],     
      license_number: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      license_expiry: ['', Validators.required],
      driver_status: ['1', Validators.required],       
    });      
   
    
  }  
  get f() { return this._editProfileForm.controls; } 
  onSubmit(){
    this._submitted = true;    
    if (this._editProfileForm.invalid) {
      return;
    }      
    this._CorporateVendorService.addCorporateDriver(this._editProfileForm.value).subscribe(
      res => {          
        this._status=res['status'];
        this._msg=res['message'];
        if(this._status==1){
          this._Router.navigate(['corp-vendor']); 
        }        
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Update. Because of Internal Server Error,Try again Later';              
      });
  }
  WINumber='+965';
  onKeyUpMobile(event){
    this.WINumber = event.target.value;    
  }
}
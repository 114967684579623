import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-favourite-route',
  templateUrl: './favourite-route.component.html',
  styleUrls: ['./favourite-route.component.css']
})
export class FavouriteRouteComponent implements OnInit {
  public spinkit = Spinkit;
  _status:number;
  _msg:string;
  _favData:any;
  _favDataLength:number;
  constructor(
    private _UserService:UserService,
    public _UserValidation:UserValidationService,  
   
  ) { }
  ngOnInit() {   
    this._UserService.getFavoritesRoute().subscribe(res=>{ 
        this._favData=res['data'];
        this._status=res['status'];
        this._msg=res['message'];  
        this._favDataLength= res['data'].length;
       
        
      },error=>{
        console.log(error);
    });     
  }
} 
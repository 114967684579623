import { Component, OnInit } from '@angular/core';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { DataService } from 'src/app/_services/data.service';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
import { DriverTripService } from 'src/app/_services/driver-trip.service';
declare var $: any;
@Component({
  selector: 'app-corporate-vendor-route-trips',
  templateUrl: './corporate-vendor-route-trips.component.html',
  styleUrls: ['./corporate-vendor-route-trips.component.css']
})
export class CorporateVendorRouteTripsComponent implements OnInit {
  public spinkit = Spinkit;
  _createTripForm :FormGroup;  
  _submitted = false;
  _DestinationType=[];
  _status:number;
  _msg:string;

  /*_Route={
    route_id:'',
    destination_type_id:'',
    location_a:'',
    location_b:'',
    via_locations:'',    
  }*/
  days_trip=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thus' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ];
  _Route:any;//={};
  _AllRouteTrips:any;
  _RouteTrips:any;
  _RouteTripsLength:boolean;
  s_point:string='';
  e_point:string='';
  v_locations:string='';
  a_route_id:number;
  inoutTime:string='inbound';
  selected_trip_id:number;
  selected_route_id:number;
  constructor(
    private _formBuilder: FormBuilder,
    private _DataService:DataService,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
    public _UserValidation:UserValidationService,
    private _DriverTripService:DriverTripService,
  ) {}

  ngOnInit() {      
    this._createTripForm=this._formBuilder.group({
      route_id:['',Validators.required], 
      trip_id:['',Validators.required],     
    });  
    const route_id= this._ActivatedRoute.snapshot.params.id;    
    this.getRoutesTrip(route_id);
  }
  get f() { return this._createTripForm.controls; }  
  onSubmit(){    
    this._submitted = true;    
    if (this._createTripForm.invalid) {
      return;
    }       
    this._DriverTripService.assignCorporateTrip(this._createTripForm.value).subscribe(res=>{      
      this._status=res['status'];
      this._msg=res['message'];
      this.getRoutesTrip(this._createTripForm.value['route_id']); 
      $('.vr-modal').find('.close').click();
    },
    error => {        
      console.log(error);
      this._status=0;
      this._msg='Unable to submit. Because of Internal Server Error,Try again Later';      
    });   
  } 
  getRoutesTrip(route_id){
    this._RouteTripsLength=false;
    this._CorporateVendorService.getRoutesTrip(route_id).subscribe(res=>{     
      this._Route=res['data']['route'];
      this._AllRouteTrips=res['data']['all_route_trips'];
      this._RouteTrips=res['data']['assign_trips'];   
      this.a_route_id=res['data']['route']['route_id']
      this.s_point=res['data']['route']['location_a'];
      this.e_point=res['data']['route']['location_b'];
      this.v_locations=res['data']['route']['via_locations']; 
      if(this._RouteTrips.length>0){
        this._RouteTripsLength=true;
      }    
    },error => {        
      console.log(error);     
    });
  }
  
  assignTrip(route_id,trip_id){
    this.selected_trip_id=trip_id;
    this.selected_route_id=route_id;
  }
}
import { Component, OnInit } from '@angular/core';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
import { UploadService } from 'src/app/_services/upload.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-vendor-profile',
  templateUrl: './corporate-vendor-profile.component.html',
  styleUrls: ['./corporate-vendor-profile.component.css']
})
export class CorporateVendorProfileComponent implements OnInit {
  public spinkit = Spinkit;

  _editProfileForm :FormGroup;
  _editProfilePictureForm :FormGroup;
  _submitted = false;

  _vendorAvatar:any; 
  _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  _corporatevendorData={
    name: '',
    phone_number: '',      
    email: '',   
    company_exit: '',   
    company_name: '',   
    company_address: '',
    block_number: '',
    street_number: '',
    building_number: '',
    no_of_vehicles:'',  
    contact_name:'',
    contact_email:'',    
    contact_phone:'+965',
    company_id:'0',
    logo_path:'',
    company_logo:'',
  }
  phone="+965";
  uploadResponse;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,    
    private uploadService:UploadService,    
    public _UserValidation:UserValidationService,
  ) { }

  ngOnInit() {     
   
    this._editProfileForm = this._formBuilder.group({
    name: ['',[ Validators.required,Validators.pattern]],
    phone_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(12)]],
    company_name: ['',Validators.required],
    company_address: ['',Validators.required],
    block_number: ['',Validators.required],
    street_number: ['',Validators.required],
    building_number: ['',Validators.required],
    no_of_vehicles: ['',Validators.required], 
    contact_name: ['',[ Validators.required,Validators.pattern]],
    contact_email: ['', [Validators.required,Validators.email]],
    contact_phone: ['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(12)]],     
    company_type_id: ['2',Validators.required],
    vender_id: ['2',Validators.required],
    company_exit: ['',Validators.required],

   });     
   this._editProfilePictureForm = this._formBuilder.group({
     avatar: ['', Validators.required],
     company_id: ['', Validators.required],
   });       
   
   this._CorporateVendorService.getCorporateVendorProfile().subscribe(dat=>{      
     this._corporatevendorData=dat['data']['corporate_vendor'];   
      if(dat['data']['corporate_vendor']['contact_phone']){
        this.phone=dat['data']['corporate_vendor']['contact_phone'];
      }        
   },
   error => {        
     console.log(error);     
   });
   

  }
 
  get f() { return this._editProfileForm.controls; }
  onSubmit() {
    this._submitted = true;
  
    if (this._editProfileForm.invalid) {
      return;
    } 
    this._CorporateVendorService.updateCorporateVendorProfile(this._editProfileForm.value).subscribe(
      res => {          
        this._status=res['status'];
        this._msg=res['message'];
        if(this._status==1){
          this._Router.navigate(['corp-vendor']);
        }
        
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Update. Because of Internal Server Error,Try again Later';              
    });
  }
  public imagePath;
  imgURL: any;  
  imgURLSUCC='./assets/images/dummy-profile-pic.png';
  public message: string;
   PictureValid:boolean=true;
   onFileSelect(event) {
    this.PictureValid=false;
    this.message ='';
    if (event.target.files.length === 0)
      return; 
    var mimeType =  event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.PictureValid=true;
      this.message = "Only images are supported.";
      return;
    } 
    var reader = new FileReader();
    this.imagePath =  event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._editProfilePictureForm.get('avatar').setValue(file);      
    }
  }
  onSubmitImage() {    
    const formData = new FormData();
    formData.append('avatar', this._editProfilePictureForm.get('avatar').value);
    formData.append('company_id',this._editProfilePictureForm.get('company_id').value);
    this.uploadService.uploadCopanyLogoFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;          
          this._status1=res['status'];
          this._msg1=res['message'];
          if(this._status1==1){
            this.imgURLSUCC=this.imgURL;           
          }          
      },
      (err) => {  
        console.log(err);
        this._status1=0;
        this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
      }
    );
  }

}
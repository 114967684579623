import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UrlApiService } from './url-api.service';
import { AuthenticationService } from './authentication.service';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CorporateUserService {

  httpOptions:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    private _AuthenticationService:AuthenticationService,
    private datePipe: DatePipe,
  ) {
      this.httpOptions = {
        headers: new HttpHeaders(
          { 'Content-Type': 'application/json; charset=utf-8',          
          })
      }
   } 
   getCorporateUserProfile(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_user_profile', user,this.httpOptions);
  }
  updateCorporateUserProfile(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;  
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_user_profile_update',user,this.httpOptions);
  }
  
  addRequest(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;  
    let _url=this._urlApi._getUrl();    
    let today =  user['start_date'];
    user['start_date']=this.datePipe.transform(today,"yyyy-MM-dd");     
   
    return this._Http.post(_url+'corporate_request',user,this.httpOptions);
  }
  addMultiRequest(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;  
    let _url=this._urlApi._getUrl();  
  
    return this._Http.post(_url+'corporate_multi_request',user,this.httpOptions);
  }
  getCorporateRequest(){
    let user_id=this._AuthenticationService.userId;
    let user={};
    user['user_id']=user_id;  
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_request',user,this.httpOptions);
  }
  getCorporateUserBookinge(corporate_request_id){
    let user_id=this._AuthenticationService.userId;
    let user={};
    user['user_id']=user_id;  
    user['corporate_request_id']=corporate_request_id;
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_user_booking_details',user,this.httpOptions);
  }
}
 
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from '../_models/user';
import { UrlApiService } from './url-api.service';
import { AuthenticationService } from './authentication.service';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class DriverService {
  httpOptions:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    private _AuthenticationService:AuthenticationService,
    private datePipe: DatePipe,

  ) { 
    this.httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json; charset=utf-8',     
        
        })
    }
    
  } 
  getDriverProfile(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'driver_profile', user,this.httpOptions);
  }
  getDriverAvatar(){
    let user_id=this._AuthenticationService.userId;
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'driver_avatar', user,this.httpOptions);
  }  
  updateDriverProfile(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;       
    let rental_timings=user['rental_timings'].join(",");     
    user['rental_timings']=rental_timings;
    let rental_timings_end=user['rental_timings_end'].join(",");     
    user['rental_timings_end']=rental_timings_end;
    let rent_days=user['rent_days'].join(",");     
    user['rent_days']=rent_days;     
    let today =  user['dob'];
    user['dob']=this.datePipe.transform(today,"yyyy-MM-dd");   
    let today1 =  user['license_expiry'];
    user['license_expiry']=this.datePipe.transform(today1,"yyyy-MM-dd");  
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'update_driver_profile', user,this.httpOptions);
  }
  getDriverAssignVehicle(){
    let user_id=this._AuthenticationService.userId;    
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'driver_assign_vehicles', user,this.httpOptions);
  }
  getVehicleType(){
    let _url=this._urlApi._getUrl();
    return this._Http.get(_url+'vehicles_type');
  }
  VehicleAssign(vehicles:any){
    let user_id=this._AuthenticationService.userId;    
    vehicles['user_id']=user_id;      
    let today = new Date();
    let assign_date=new Date(today.setFullYear(today.getFullYear() + 40));
    let last_assign_date=assign_date.toISOString().slice(0, 10);
    vehicles['last_assign_date']=last_assign_date; 
   
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'assign_vehicles_driver',vehicles,this.httpOptions);
  }
  getDriverAssignRoute(){
    let user_id=this._AuthenticationService.userId;      
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'driver_assign_route', user,this.httpOptions);
  }
  getDriverAssignRoutes(){
    let user_id=this._AuthenticationService.userId;      
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'all_driver_assign_routes', user,this.httpOptions);
  }
  DriverAssignRouteTrip(route_id:number){
    let user_id=this._AuthenticationService.userId;      
    let dta={
      'user_id':user_id,
      'route_id':route_id, 
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'driver_assign_route_trip', dta,this.httpOptions);
  }
  ChangeTripTime(data1){
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'change_trip_time', data1,this.httpOptions);
  }
  
  ChangeTripOffers(data1){
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'change_trip_offers', data1,this.httpOptions);
  }


  ChangeTripSeat(data1){
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'change_trip_seat', data1,this.httpOptions);
  }
  
  ChangeStatus(data1){
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'change_trip_status', data1,this.httpOptions);
  } 
  
}


import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-quotes',
  templateUrl: './corporate-vendor-quotes.component.html',
  styleUrls: ['./corporate-vendor-quotes.component.css']
})
export class CorporateVendorQuotesComponent implements OnInit {

  public spinkit = Spinkit;
  quotations:any;
  image_path:string;
  vehicle_path:string;
  company_logo_path:string;
  constructor(
    private _CorporateVendorService:CorporateVendorService,

  ) { }

  ngOnInit() {
    this._CorporateVendorService.getQuotations().subscribe(res=>{
      this.quotations=res['data']['quotations'];     
      this.vehicle_path=res['data']['vehicle_path'];       
    },error => {        
      console.log(error);     
    });
  }
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgSelect2Module } from 'ng-select2';
import { SlickModule } from 'ngx-slick';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { BarRatingModule } from "ngx-bar-rating";
import { CountUpModule } from 'countup.js-angular2';
// site Layout Component
import { HeaderComponent } from './component/_layout/header/header.component';
import { FooterComponent } from './component/_layout/footer/footer.component';
import { SiteLayoutComponent } from './component/_layout/site-layout/site-layout.component';

// Page Component

import { LandingComponent } from './component/landing/landing.component';
import { IndividualUserComponent } from './component/individual-user/individual-user.component';
import { VendorComponent } from './component/vendor/vendor.component';
import { CorporateComponent } from './component/corporate/corporate.component';
import { NavComponent } from './component/_layout/nav/nav.component';
import { AboutComponent } from './component/about/about.component';
import { VServiceComponent } from './component/v-service/v-service.component';
import { AgentComponent } from './component/agent/agent.component';
import { FaqComponent } from './component/faq/faq.component';
import { RentFeetComponent } from './component/rent-feet/rent-feet.component';
import { RegisterComponent } from './component/register/register.component';
import { CancellationComponent } from './component/cancellation/cancellation.component';
import { TermsComponent } from './component/terms/terms.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { SearchComponent } from './component/search/search.component';
import { SearchDetailsComponent } from './component/search-details/search-details.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { ProfileComponent } from './component/profile/profile.component';
import { FavouriteRouteComponent } from './component/favourite-route/favourite-route.component';
import { IndividualVendorDashboardComponent } from './component/individual-vendor-dashboard/individual-vendor-dashboard.component';
import { CorporateVendorDashboardComponent } from './component/corporate-vendor-dashboard/corporate-vendor-dashboard.component';
import { CorporateUserDashboardComponent } from './component/corporate-user-dashboard/corporate-user-dashboard.component';
import { IndividualVendorProfileComponent } from './component/individual-vendor-profile/individual-vendor-profile.component';
import { IndividualVendorVehicleComponent } from './component/individual-vendor-vehicle/individual-vendor-vehicle.component';
import { IndividualVendorVehicleProfileComponent } from './component/individual-vendor-vehicle-profile/individual-vendor-vehicle-profile.component';
import { IndividualVendorVehicleGalleryComponent } from './component/individual-vendor-vehicle-gallery/individual-vendor-vehicle-gallery.component';
import { IndividualVendorRoutesComponent } from './component/individual-vendor-routes/individual-vendor-routes.component';
import { IndividualVendorSingleRouteComponent } from './component/individual-vendor-single-route/individual-vendor-single-route.component';
import { IndividualVendorCreateTripComponent } from './component/individual-vendor-create-trip/individual-vendor-create-trip.component';
import { CorporateVendorProfileComponent } from './component/corporate-vendor-profile/corporate-vendor-profile.component';
import { CorporateVendorVehiclesComponent } from './component/corporate-vendor-vehicles/corporate-vendor-vehicles.component';
import { CorporateVendorVehicleComponent } from './component/corporate-vendor-vehicle/corporate-vendor-vehicle.component';
import { CorporateVendorDriversComponent } from './component/corporate-vendor-drivers/corporate-vendor-drivers.component';
import { CorporateVendorDriverComponent } from './component/corporate-vendor-driver/corporate-vendor-driver.component';
import { CorporateVendorAddVehicleComponent } from './component/corporate-vendor-add-vehicle/corporate-vendor-add-vehicle.component';
import { CorporateVendorEditVehicleComponent } from './component/corporate-vendor-edit-vehicle/corporate-vendor-edit-vehicle.component';
import { CorporateVendorAddDriverComponent } from './component/corporate-vendor-add-driver/corporate-vendor-add-driver.component';
import { CorporateVendorEditDriverComponent } from './component/corporate-vendor-edit-driver/corporate-vendor-edit-driver.component';
import { CorporateUserProfileComponent } from './component/corporate-user-profile/corporate-user-profile.component';
import { CorporateUserRequestQuoteComponent } from './component/corporate-user-request-quote/corporate-user-request-quote.component';
import { CorporateVendorAddRouteComponent } from './component/corporate-vendor-add-route/corporate-vendor-add-route.component';
import { CorporateVendorEditRouteComponent } from './component/corporate-vendor-edit-route/corporate-vendor-edit-route.component';
import { CorporateVendorRoutesComponent } from './component/corporate-vendor-routes/corporate-vendor-routes.component';
import { CorporateVendorAddTripComponent } from './component/corporate-vendor-add-trip/corporate-vendor-add-trip.component';
import { CorporateVendorEditTripComponent } from './component/corporate-vendor-edit-trip/corporate-vendor-edit-trip.component';
import { CorporateVendorTripsComponent } from './component/corporate-vendor-trips/corporate-vendor-trips.component';

// Form Module 
import { ReactiveFormsModule } from '@angular/forms'; 
// Services 
import { RegistrationService } from './_services/registration.service';
import { DataService } from './_services/data.service';
import { UrlApiService } from './_services/url-api.service';
import { AuthenticationService } from './_services/authentication.service';
import { UserService } from './_services/user.service';
import { DriverService } from './_services/driver.service';
import { DriverTripService } from './_services/driver-trip.service';
import { TripFilterPipe } from './_helpers/trip-filter.pipe';

//import { NgxSpinnerModule } from "ngx-spinner";
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NgHttpLoaderModule } from 'ng-http-loader';

// Social login and authentication module 
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

// Email Template
import { EmailVerifyComponent } from './component/email-verify/email-verify.component';

import { CorporateVendorAssignDriverVehiclesComponent } from './component/corporate-vendor-assign-driver-vehicles/corporate-vendor-assign-driver-vehicles.component';
import { CorporateVendorAssignTripDriverComponent } from './component/corporate-vendor-assign-trip-driver/corporate-vendor-assign-trip-driver.component';
import { CorporateUserRequestQuoteDetailsComponent } from './component/corporate-user-request-quote-details/corporate-user-request-quote-details.component';
import { CorporateVendorBookingsComponent } from './component/corporate-vendor-bookings/corporate-vendor-bookings.component';
import { CorporateVendorQuotesComponent } from './component/corporate-vendor-quotes/corporate-vendor-quotes.component';

import {DatePipe} from '@angular/common';
import { IndividualUserBookingsComponent } from './component/individual-user-bookings/individual-user-bookings.component';
import { CorporateVendorRouteTripsComponent } from './component/corporate-vendor-route-trips/corporate-vendor-route-trips.component';
 

// Fire Base Module
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from './../environments/environment';

import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { CorporateVendorCreateRouteTripComponent } from './component/corporate-vendor-create-route-trip/corporate-vendor-create-route-trip.component';
import { CorporateVendorEditRouteTripComponent } from './component/corporate-vendor-edit-route-trip/corporate-vendor-edit-route-trip.component';


let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("556905645093-i55n9i9ko5ue75vpmkfh1bfqdfehd26o.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("699078903853231")
  }
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    IndividualUserComponent,
    LandingComponent,
    SiteLayoutComponent,
    VendorComponent,
    CorporateComponent,
    NavComponent,
    AboutComponent,
    VServiceComponent,
    AgentComponent,
    FaqComponent,
    RentFeetComponent,
    RegisterComponent,
    CancellationComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    SearchComponent,
    LoginComponent,
    DashboardComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    ProfileComponent,
    IndividualVendorDashboardComponent,
    CorporateVendorDashboardComponent,
    CorporateUserDashboardComponent,
    IndividualVendorProfileComponent,
    IndividualVendorVehicleComponent,
    IndividualVendorVehicleProfileComponent,
    IndividualVendorVehicleGalleryComponent,
    IndividualVendorRoutesComponent,
    IndividualVendorSingleRouteComponent,
    IndividualVendorCreateTripComponent,
    CorporateVendorProfileComponent,
    CorporateVendorVehiclesComponent,
    CorporateVendorVehicleComponent,
    CorporateVendorDriversComponent,
    CorporateVendorDriverComponent,
    CorporateVendorAddVehicleComponent,
    CorporateVendorEditVehicleComponent,
    CorporateVendorAddDriverComponent,
    CorporateVendorEditDriverComponent,
    CorporateUserProfileComponent,
    CorporateUserRequestQuoteComponent,
    SearchDetailsComponent,
    TripFilterPipe,
    FavouriteRouteComponent,
    EmailVerifyComponent,
    CorporateVendorAddRouteComponent,
    CorporateVendorEditRouteComponent,
    CorporateVendorRoutesComponent,
    CorporateVendorAddTripComponent,
    CorporateVendorEditTripComponent,
    CorporateVendorTripsComponent,   
    CorporateVendorAssignDriverVehiclesComponent,
    CorporateVendorAssignTripDriverComponent, 
    CorporateUserRequestQuoteDetailsComponent, 
    CorporateVendorBookingsComponent, 
    CorporateVendorQuotesComponent, 
    IndividualUserBookingsComponent, 
    PageNotFoundComponent, CorporateVendorRouteTripsComponent, CorporateVendorCreateRouteTripComponent, CorporateVendorEditRouteTripComponent,  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgSelect2Module,   
    SlickModule, 
    DataTablesModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
   // NgxSpinnerModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    SocialLoginModule,
    AmazingTimePickerModule,
    BarRatingModule,
    CountUpModule,    
    AngularFireModule.initializeApp(environment.configFireBase), // FireBase
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features   
    AngularFireDatabaseModule,
    InternationalPhoneNumberModule,
  ],
  providers: [
    DataService,
    RegistrationService,
    AuthenticationService,
    UrlApiService,
    UserService,
    DriverService,
    DatePipe,
    DriverTripService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
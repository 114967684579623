import { Component, OnInit } from '@angular/core';
import { DriverService } from 'src/app/_services/driver.service';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { FormGroup,FormBuilder, Validators} from '@angular/forms';
import * as firebase from 'firebase/app';
import { UserValidationService } from 'src/app/_services/user-validation.service';
export interface driverData {
  name: string;
  email: string;
  phone_number:string;
  civil_id: string;
  company_name:string;
  company_address:string;
  block_number:string;
  street_number:string;
  building_number:string;
  no_of_vehicles:number;
  company_exit:string;
  whatsapp_number: string;
  imo_number: string;
  driver_nationality:string;
  gender: string;
  dob: string;
  license_number: string;
  license_expiry: string;
  rent_vehicle: string;
  rent_days: string;
  rental_timings: string;
  want_agent:string;
}
@Component({
  selector: 'app-individual-vendor-dashboard',
  templateUrl: './individual-vendor-dashboard.component.html',
  styleUrls: ['./individual-vendor-dashboard.component.css']
})
export class IndividualVendorDashboardComponent implements OnInit {
  public spinkit = Spinkit;
  _driverData:driverData;
  _chatForm:FormGroup;
  image_path: string;  
  profile_image: string; 
  _assignVehicle:any;
  _assignVehicleLength:number;
  _price:any;
  _area_route:any;
  _trips:any;
  _Routes:any;
  __showChatList;
  __showChat= 'off';
  users:any;
  allusers:any;
  chatWndowSHOW:boolean;
  _RoutesLength:number;
  constructor(
    private _AuthenticationService:AuthenticationService,
    private _DriverService:DriverService,  
    private _FormBuilder:FormBuilder,    
    public _UserValidation:UserValidationService,  
  ) {}  
  ngOnInit() {
    //console.log('USSEER',firebase.auth().currentUser.uid);
   // firebase.auth().currentUser
    this._chatForm = this._FormBuilder.group({
      chatText:['',Validators.required],     
    });    
     
    this.chatWndowSHOW=false;
    this._DriverService.getDriverProfile().subscribe(dat=>{      
      this._driverData=dat['data'];  
      // let uid=firebase.auth().currentUser.uid;       
      //  this.getUsersChatList(uid);
      //  this.getAllUsers();       
      
    },
    error => {        
      console.log(error);     
    });    
    this._DriverService.getDriverAvatar().subscribe(res=>{     
      if(res['data']['profile_image']){
        this.profile_image=res['data']['profile_image'];
        this.image_path=res['data']['image_path'];
      }    
    },error => {        
      console.log(error);     
    });    
    this._DriverService.getDriverAssignVehicle().subscribe(res=>{
      this._assignVehicle=res['data']['vehicle'];     
      this._assignVehicleLength=Object.keys(this._assignVehicle).length;
    },error => {        
      console.log(error);     
    });       
    this._DriverService.getDriverAssignRoutes().subscribe(res=>{
      this._Routes=res['data']['area_route'];
      this._RoutesLength=res['data']['area_route'].length;
    });  
  }
  openChatList(){    
    let u_id=this._AuthenticationService.FireBaseUID; 
    this.__showChatList = !this.__showChatList;
  } 
  getUsersChatList(u_id){
    //let u_id=this._AuthenticationService.FireBaseUID;     
     firebase.database().ref('Chatlist').child(u_id)     
       .on("value",snapshot => {
        if (snapshot.exists()){
          const userData = snapshot.val();            
          var resultArray = Object.keys(userData).map(function(personNamedIndex){           
            let person = userData[personNamedIndex];          
            return person;
        });     
        this.users=resultArray;
        if(resultArray){
           this.chatWndowSHOW=true;
        }
      }    
    });    
  }  
  getAllUsers(){     
     firebase.database().ref().child('Users')     
       .on("value",snapshot => {
        if (snapshot.exists()){
          const userData = snapshot.val();           
          var resultArray = Object.keys(userData).map(function(personNamedIndex){           
            let person = userData[personNamedIndex];          
            return person;
        });     
        this.allusers=resultArray;
      }    
    });    
  }
  getUserName(uid){    
    let element = this.allusers.find(elm => {     
    return elm.id == uid;
    });     
    return element.username
  }
  allChats:any;
  chatId:string='';
  openChat(show,cid){ 
    var x=firebase.database().ref().child("Chats").on("value",snapshot => {
      if (snapshot.exists()){
        const userData = snapshot.val();      
        var resultArray = Object.keys(userData).map(function(personNamedIndex){
          let person = userData[personNamedIndex];         
          return person;
      });    
      this.allChats=resultArray;    
      }      
    });  
    this.chatId=cid;
    this.__showChat = show;
  }
  chatText:string;
  chatTextTest:string;
  doCharting(){
    if(this.chatTextTest!=''){
      let u_id=this._AuthenticationService.FireBaseUID;
      let chatData = {  
          isseen: true,   
          message: this._chatForm.value.chatText,
          receiver: this.chatId,
          sender: u_id,
      };
      firebase.database().ref().child('/Chats').push(chatData); 
      let r_id=this.chatId; 
      var ref = firebase.database().ref('Chatlist').child(u_id).child(r_id);ref.once("value").then(function(snapshot){
        var a = snapshot.exists();
          let cldata={id:u_id,};     
          if(!a){
            ref.child("id").set(cldata);     
            ref.set(cldata);
          }    
        }); 
      var ref1 = firebase.database().ref('Chatlist').child(r_id).child(u_id);  ref1.once("value").then(function(snapshot){
        var a = snapshot.exists();
        let cldata={id:u_id,};
        if(!a){    
          ref1.child("id").set(cldata);      
          ref1.set(cldata);
        }   
      });     
      this.chatTextTest='';      
    }    
  }
  ngAfterViewInit() {
    // viewChild is set after the view has been initialized   
    let uid=firebase.auth().currentUser.uid;       
    this.getUsersChatList(uid);
    this.getAllUsers();     
    //console.clear();
  }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup ,FormArray, FormControl,FormBuilder,Validators} from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { DataService } from 'src/app/_services/data.service';
import { DriverTripService } from 'src/app/_services/driver-trip.service';
import { Time } from '@angular/common';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-individual-vendor-create-trip',
  templateUrl: './individual-vendor-create-trip.component.html',
  styleUrls: ['./individual-vendor-create-trip.component.css']
})
export class IndividualVendorCreateTripComponent implements OnInit {
  public spinkit = Spinkit;
  _createTripForm :FormGroup;
  public _LocationData: Array<NgSelect2Module>;
  
  routeData=[];
  area_route:any;
  price:any;    
  
  trips:any;
  selectedTime:Time;
  selectedNatureTrip:string;
  selectedTripId=0;
  buttonDisabled:boolean=true;
  _status:number;
  _msg:string;
  offers=[
    { id: 1, name: 'Discounts' },
    { id: 2, name: 'Wifi' },
    { id: 3, name: 'Online Payment' },
    { id: 4, name: 'Ladies Discounts' },    
  ];
  constructor(
    private _Router: Router,
    private _FormBuilder:FormBuilder, 
    private _DataService:DataService,
    private _DriverTripService:DriverTripService,
    public _UserValidation:UserValidationService,
  ) { }

  ngOnInit() {
    this._createTripForm=this._FormBuilder.group({
      route_id:[''],
      oneway_price:['',Validators.required],
      round_price:['',Validators.required], 
      start_time:['00:00:00',Validators.required],
      end_time:['00:00:00',Validators.required],
      trip_id:[''],
      available_seats:['',Validators.required],      
      offers: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),             
      ]),       
    });           
      this._DataService._getVehicleRoutes().subscribe(res=>{     
        this.routeData=res['data'];      
      });        
  }
  showTripDiv:boolean=false;
  routeChanged(evt){
    this.price={
      'one_way_trip_price':'',
      'round_way_trip_price':'',
    }; 
    this._status=2;   
    this.showTripDiv=true; 
    let route_id=evt.target.value;    
    this._DriverTripService.getDriverTrip(route_id).subscribe(res=>{     
      this.area_route=res['data']['area_route'];   
      let priceLength=Object.keys(res['data']['price']).length;    
      if(priceLength>0){
        this.price=res['data']['price']
      }    
      this.trips=res['data']['trips'];       
    });    
  }  
  timeHandleChange(trip_id,selectedtime,naturetrip){
    this.selectedTime=selectedtime;
    this.selectedNatureTrip=naturetrip;
    this.selectedTripId=trip_id;
    this.buttonDisabled=false;
  }
  get f() { return this._createTripForm.controls; }
  _submitted = false;
  reqMsg:boolean=false;
  onSubmit(){   
    this._submitted = true;
    this.reqMsg=true;    
    
    if (this._createTripForm.invalid) {
      return;
    }      
    const selectedrentDays = this._createTripForm.value.offers
    .map((v, i) => v ? this.offers[i].name : null)
    .filter(v =>v);  
    this._createTripForm.value.offers=selectedrentDays.join();    
    if(this.selectedNatureTrip=='inbound'){
      this._createTripForm.value['end_time']=this.selectedTime;
    }else if(this.selectedNatureTrip=='outbound'){
      this._createTripForm.value['start_time']=this.selectedTime;
    }        
    this._DriverTripService.assignDriverTrip(this._createTripForm.value).subscribe(res=>{      
      this._status=res['status'];
      this._msg=res['message'];       
      
      this.showTripDiv=false; 
      let selected_route_id=this._createTripForm.value.route_id
      this._Router.navigate(['indv-vendor/route/'+selected_route_id]);   
      this.reqMsg=false; 
      return false;
    },
    error => {        
      console.log(error);
      this._status=0;
      this._msg='Unable to submit. Because of Internal Server Error,Try again Later';

    });    
  }  
}
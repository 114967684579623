import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-bookings',
  templateUrl: './corporate-vendor-bookings.component.html',
  styleUrls: ['./corporate-vendor-bookings.component.css']
})
export class CorporateVendorBookingsComponent implements OnInit {
  public spinkit = Spinkit;
  _bookings:any;
  image_path:string;
  vehicle_path:string;
  company_logo_path:string;
  constructor(
    private _CorporateVendorService:CorporateVendorService,

  ) { }

  ngOnInit() {
   
    this._CorporateVendorService.getBookings().subscribe(res=>{
      this._bookings=res['data']['bookings'];
      this.image_path=res['data']['image_path'];  
      this.vehicle_path=res['data']['vehicle_path'];  
      this.company_logo_path=res['data']['company_logo_path'];  
     
    },error => {        
      console.log(error);     
    });
  }

}
 
import { Component, OnInit } from '@angular/core';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { UploadService } from 'src/app/_services/upload.service';
@Component({
  selector: 'app-corporate-vendor-vehicle',
  templateUrl: './corporate-vendor-vehicle.component.html',
  styleUrls: ['./corporate-vendor-vehicle.component.css']
})
export class CorporateVendorVehicleComponent implements OnInit {
  public spinkit = Spinkit;
  vehicleData:any;
  vehicle_path:string;
  _editVechilePictureForm :FormGroup;
  _addVechileGalleryForm :FormGroup;
  slideConfig={};
  slideConfigThumb={};
  _vehicle_id:number;
  _VehicleGallery:any;  
  _VehiclePath:string; 
  PictureValid:boolean=true;
  PictureValid1:boolean=true;
  constructor(
    private _CorporateVendorService:CorporateVendorService,
    private _ActivatedRoute:ActivatedRoute,
    private _formBuilder: FormBuilder,
    private uploadService:UploadService, 
    private _Router:Router,
    ) { }

  ngOnInit() {
    this._editVechilePictureForm = this._formBuilder.group({    
      avatar: ['', Validators.required],
      vehicle_id:['', Validators.required],
    });  
    this._addVechileGalleryForm = this._formBuilder.group({    
      avatar: ['', Validators.required],
      vehicle_id:['', Validators.required],
    }); 
    this.slideConfig = {
      slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      arrows: false, fade: true, asNavFor: '.slider-nav'
    };
    this.slideConfigThumb = {
      slidesToShow: 3, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      dots: false, centerMode: true, focusOnSelect: true, asNavFor: '.slider-for'
    };
    const vehicle_id= this._ActivatedRoute.snapshot.params.id;   
    this.getVichles(vehicle_id);    

  }
 
 public imagePath;
 imgURL: any;
 imgURL1: any;
 imgURLSUCC:any;
 public message: string; 
 public message1: string; 
 _status:number;
_msg:string;
_status1:number;
_msg1:string;
  onFileSelect(event) {
    this.message ='';
   if (event.target.files.length === 0)
     return; 
   var mimeType =  event.target.files[0].type;
   if (mimeType.match(/image\/*/) == null) {
     this.message = "Only images are supported.";
     return;
   } 
   var reader = new FileReader();
   this.imagePath =  event.target.files;
   reader.readAsDataURL(event.target.files[0]); 
   reader.onload = (_event) => { 
     this.imgURL1 = reader.result;      
     this.PictureValid=false;
   }
   if (event.target.files.length > 0) {
     const file = event.target.files[0];
     this._editVechilePictureForm.get('avatar').setValue(file);      
   }
 }

 onSubmitImage() {    
   const formData = new FormData();
   formData.append('avatar', this._editVechilePictureForm.get('avatar').value);
   formData.append('vehicle_id',this._editVechilePictureForm.get('vehicle_id').value);
   this.uploadService.uploadVehicleFile(formData).subscribe(
     (res) => {              
         this._status=res['status'];
         this._msg=res['message'];
         if(this._status==1){
          this.PictureValid=true;        
           this.imgURLSUCC=this.imgURL1;           
         }          
     },
     (err) => {  
       console.log(err);
       this._status=0;
       this._msg='Unable to update. Because of Internal Server Error,Try again Later'; 
     }
   );
 }
 onFileSelectGallary(event) {
  this.message1 ="";
  if (event.target.files.length === 0)
    return; 
  var mimeType =  event.target.files[0].type;
  if (mimeType.match(/image\/*/) == null) {
    this.message1 = "Only images are supported.";
    return;
  } 
  var reader = new FileReader();
  this.imagePath =  event.target.files;
  reader.readAsDataURL(event.target.files[0]); 
  reader.onload = (_event) => { 
    this.imgURL = reader.result;   
    this.PictureValid1=false;   
  }
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    this._addVechileGalleryForm.get('avatar').setValue(file);      
  }
}
onSubmitImageGallery() {    
  const formData = new FormData();
  formData.append('avatar', this._addVechileGalleryForm.get('avatar').value);
  formData.append('vehicle_id',this._addVechileGalleryForm.get('vehicle_id').value);
  this.uploadService.uploadVehicleGalleryFile(formData).subscribe(
    (res) => {              
        this._status1=res['status'];
        this._msg1=res['message'];
        if(this._status1==1){
          this.PictureValid1=true; 
          const vehicle_id= this._ActivatedRoute.snapshot.params.id;            
          this.getVichles(vehicle_id);
        }          
    },
    (err) => {  
      console.log(err);
      this._status1=0;
      this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
    }
  );
}
getVichles(vehicle_id){
  this._CorporateVendorService.getCorporateVendorVehicle(vehicle_id).subscribe(dat=>{      
    this.vehicleData=dat['data']['corporate_vehicle'];
    this._vehicle_id=dat['data']['corporate_vehicle']['vehicle_id'];
    this.vehicle_path=dat['data']['vehicle_path'];        
    this._VehicleGallery=dat['data']['vehicle_gallery'];       
  },
  error => {        
    console.log(error);     
  }); 
} 
}
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { UrlApiService } from './url-api.service';
@Injectable({
  providedIn: 'root'
})
export class DriverTripService {
  httpOptions:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    private _AuthenticationService:AuthenticationService,

  ) {
    this.httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json; charset=utf-8',     
        
        })
    }
   }
  getDriverTrip(route_id){
    let user_id=this._AuthenticationService.userId;
    
    let user={
      'user_id':user_id,
      'route_id':route_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_driver_trips', user,this.httpOptions);
  }
  assignDriverTrip(data:any){
    let user_id=this._AuthenticationService.userId;    
    data['user_id']=user_id;
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'assign_driver_trip', data,this.httpOptions);
  }
  
  getCorporateTrip(route_id){
    let user_id=this._AuthenticationService.userId;    
    let user={
      'user_id':user_id,
      'route_id':route_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_driver_trips', user,this.httpOptions);
  }
  assignCorporateTrip(data:any){
    let user_id=this._AuthenticationService.userId;    
    data['user_id']=user_id;
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'assign_corporate_trip', data,this.httpOptions);
  }
  getAssignCorporateTrip(route_id){
    let user_id=this._AuthenticationService.userId;    
    let user={
      'user_id':user_id,
      'route_id':route_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_assign_corporate_driver_trips', user,this.httpOptions);
  }
  
}

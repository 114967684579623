import { Component, OnInit } from '@angular/core';
import { CorporateUserService } from 'src/app/_services/corporate-user.service';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-user-dashboard',
  templateUrl: './corporate-user-dashboard.component.html',
  styleUrls: ['./corporate-user-dashboard.component.css']
})
export class CorporateUserDashboardComponent implements OnInit {
  public spinkit = Spinkit;
  _corporateuserData:any;
  _corporateRequestData:any;
  _corporateRequestDataLength:number;
  constructor(
    private _CorporateUserService:CorporateUserService,
    public _UserValidation:UserValidationService,
    ) { }
  ngOnInit() {    
    this._CorporateUserService.getCorporateUserProfile().subscribe(dat=>{      
      this._corporateuserData=dat['data']['corporate_user'];     
    },
    error => {        
      console.log(error);     
    });    
    this._CorporateUserService.getCorporateRequest().subscribe(dat=>{      
      this._corporateRequestData=dat['data'];  
      this._corporateRequestDataLength=dat['data'].length;    
    },
    error => {        
      console.log(error);     
    });
  }  
}
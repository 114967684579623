import { Component, OnInit } from '@angular/core';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
import { UploadService } from 'src/app/_services/upload.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-vendor-edit-driver',
  templateUrl: './corporate-vendor-edit-driver.component.html',
  styleUrls: ['./corporate-vendor-edit-driver.component.css']
})
export class CorporateVendorEditDriverComponent implements OnInit {
  _editProfileForm :FormGroup;
  _editProfilePictureForm :FormGroup;
  _submitted = false;
  _showRental = 'n';
  public spinkit = Spinkit;
  _corporatedriverData:any;
  image_path:string;  
  _driverAvatar:any; 
  _driverData={
    corporate_driver_id:'',
    driver_name: '',
    driver_email:'',
    phone_number: '',   
    civil_id: '',
    email: '',
    gender: '',
    dob: '',   
    whatsapp_number: '',
    imo_number: '',
    driver_nationality:'',   
    license_number: '',
    license_expiry: '',
    driver_status: '',   
    profile_image:'', 
  }
  _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  maxDate: Date;
  uploadResponse;

  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _ActivatedRoute:ActivatedRoute,
    private uploadService:UploadService, 
    private _Router:Router, 
    public _UserValidation:UserValidationService, 
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);
   }

  ngOnInit() {
    this._editProfileForm = this._formBuilder.group({
      driver_name: ['',[ Validators.required,Validators.pattern]],     
      phone_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      civil_id: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      gender: ['Male', Validators.required],
      dob: ['', Validators.required],
      whatsapp_number: ['',[ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      imo_number: ['',[ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      driver_nationality: ['', Validators.required],     
      license_number: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      license_expiry: ['', Validators.required],
      driver_status: ['1', Validators.required],    
      corporate_driver_id:['', Validators.required],      
    });      
    this._editProfilePictureForm = this._formBuilder.group({
      avatar: ['', Validators.required],
      corporate_driver_id: ['', Validators.required],
    });    
    const corporate_driver_id= this._ActivatedRoute.snapshot.params.id;    
    this._CorporateVendorService.getCorporateDriver(corporate_driver_id).subscribe(dat=>{      
      this._corporatedriverData=dat['data']['corporate_driver'];
      this._driverData=dat['data']['corporate_driver'];
      this.image_path=dat['data']['image_path']; 
    },
    error => {        
      console.log(error);     
    });
  }
  showRental(showRental) {    
    this._showRental = showRental;
  }
  get f() { return this._editProfileForm.controls; }
  onSubmit() {    
    this._submitted = true;      
    if (this._editProfileForm.invalid) {
      return;
    } 
    this._CorporateVendorService.updateCorporateDriver(this._editProfileForm.value).subscribe(
      res => {          
        this._status=res['status'];
        this._msg=res['message'];         
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Update. Because of Internal Server Error,Try again Later';              
      });
  }
  public imagePath;
  imgURL: any;  
  imgURLSUCC='./assets/images/dummy-profile-pic.png';
  public message: string;   
   onFileSelect(event) {
    if (event.target.files.length === 0)
      return; 
    var mimeType =  event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    } 
    var reader = new FileReader();
    this.imagePath =  event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._editProfilePictureForm.get('avatar').setValue(file);      
    }
  }
  onSubmitImage() {    
    const formData = new FormData();
    formData.append('avatar', this._editProfilePictureForm.get('avatar').value);
    formData.append('corporate_driver_id',this._editProfilePictureForm.get('corporate_driver_id').value);
    this.uploadService.uploadCorporateDriverFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;          
          this._status1=res['status'];
          this._msg1=res['message'];
          if(this._status1==1){
            this.imgURLSUCC=this.imgURL;           
          }          
      },
      (err) => {  
        console.log(err);
        this._status1=0;
        this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
      }
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup,FormArray,FormControl,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverService } from 'src/app/_services/driver.service';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
declare var $: any;
@Component({
  selector: 'app-individual-vendor-single-route',
  templateUrl: './individual-vendor-single-route.component.html',
  styleUrls: ['./individual-vendor-single-route.component.css']
})
export class IndividualVendorSingleRouteComponent implements OnInit {
  public spinkit = Spinkit;
  _editTripTime :FormGroup;
  _editTripSeat:FormGroup;
  _editTripOffers:FormGroup;
  _seatSubmitted:boolean;
  avl_seat:number=0;
  avl_time='00:00';
  offers=[
    { id: 1, name: 'Discounts' },
    { id: 2, name: 'Wifi' },
    { id: 3, name: 'Online Payment' },
    { id: 4, name: 'Ladies Discounts' },    
  ];

  

  constructor(
    private _formBuilder: FormBuilder,
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
    private _DriverService:DriverService,
    public _UserValidation:UserValidationService,
  ) { }
  _Route:any;
  _Price:any;
  _Trips:any//:={};
  ngOnInit() { 
    this._editTripTime = this._formBuilder.group({
      times: ['00:00', Validators.required],    
    });
    this._editTripSeat = this._formBuilder.group({
      available_seats: ['', Validators.required],
    });    
    this._editTripOffers = this._formBuilder.group({
      offers: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),             
      ]),   
    
    }); 

    this.getDriverAssignRouteTrip();
  }
  ChangeStatus(driver_trip_id,trip_status){
    let data={
      'driver_trip_id':driver_trip_id,
      'trip_status':trip_status,      
    }
    this._DriverService.ChangeStatus(data).subscribe(res=>{
      this.getDriverAssignRouteTrip();
    });  
  }
  onSubmit(driver_trip_id,nature_trip){ 
    this.avl_time='00:00';  
    let data={
      'driver_trip_id':driver_trip_id,
      'times':this._editTripTime.value.times,
      'nature_trip':nature_trip,
    }
    this._DriverService.ChangeTripTime(data).subscribe(res=>{ });    
    $('.time_close').find('.close').click();
    this.getDriverAssignRouteTrip();     
  }  

  /********************** */
  onSubmitOffers(driver_trip_id){       
    const selectedrentDays = this._editTripOffers.value.offers
    .map((v, i) => v ? this.offers[i].name : null)
    .filter(v =>v);  
    this._editTripOffers.value.offers=selectedrentDays.join();  

   
    let data={
      'driver_trip_id':driver_trip_id,
      'offers':this._editTripOffers.value.offers,    
    }
   
    this._DriverService.ChangeTripOffers(data).subscribe(res=>{ });    
    $('.offer_close').find('.close').click();
    this.getDriverAssignRouteTrip();
  }
  /************************** */
  get f() { return this._editTripSeat.controls; }
  ChangeSeats(driver_trip_id){ 
    this._seatSubmitted=true;  
    if (this._editTripSeat.invalid) {
      return;
    } else{
      
      let data={
        'driver_trip_id':driver_trip_id,
        'available_seats':this._editTripSeat.value.available_seats,      
      }
      this._DriverService.ChangeTripSeat(data).subscribe(res=>{ 
        this.getDriverAssignRouteTrip(); 

      }); 
      this.avl_seat=0;
      $('.seats_close').find('.close').click();     
    }         
  } 
  getDriverAssignRouteTrip(){
    const route_id= this._ActivatedRoute.snapshot.params.id;    
    this._DriverService.DriverAssignRouteTrip(route_id).subscribe(res=>{      
      this._Price=res['data']['price'];
      this._Route=res['data']['area_route'];
      this._Trips=res['data']['trips'];     
    });
  }
  TakeSeat(seat){
    this.avl_seat=seat;    
  }
  TakeTime(ttime){
    this.avl_time=ttime;    
  }
}
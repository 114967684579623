import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-v-service',
  templateUrl: './v-service.component.html',
  styleUrls: ['./v-service.component.css']
})
export class VServiceComponent implements OnInit {
  public spinkit = Spinkit;
  constructor() { }
  ngOnInit() {
  }
}
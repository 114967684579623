import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup , FormBuilder,Validators} from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import { UserService } from '../../_services/user.service';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public spinkit = Spinkit;
  _changePasswordForm :FormGroup;
  _submitted = false;
  _status:number;
  _msg:string;
  oldPasswordNotEmpty:string;
  passwordNotEmpty:string;
  confirmPasswordNotEmpty:string;
  constructor(
    private _Router: Router,
    private _FormBuilder:FormBuilder,  
    private _userService:UserService,  
    public _AuthenticationService:AuthenticationService,
  ) { }

  ngOnInit() {
    this._changePasswordForm=this._FormBuilder.group({
      old_password:['', Validators.required],     
      password: ['', [Validators.required, Validators.minLength(8),Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[@#$%^&+*!=]).{8,30}$/)]],
      confirmPassword: ['', Validators.required],
    },{
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get f() { return this._changePasswordForm.controls; }
  onSubmit(){
    this._submitted = true;   
    if (this._changePasswordForm.invalid) {
      return;
    }      
    this._userService.changePassword(this._changePasswordForm.value).subscribe(
      res => {         
        this._status=res['status'];
        this._msg=res['message']; 
		this._submitted = false;  
		this._changePasswordForm.reset();
	   return false;
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Change password. Because of Internal Server Error,Try again Later';                 
      });

  }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/_services/data.service';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from '../../_services/authentication.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public spinkit = Spinkit;
  _contactForm :FormGroup;
  _submitted = false;
  reqMsg:boolean=false;
  _status:number;
  _msg:string;
  UserName="";
  userEmail=""; 
  userPhone="+965";
  constructor(
    private _FormBuilder:FormBuilder, 
    private _DataService:DataService, 
    private _AuthenticationService:AuthenticationService,
  ) {
    if(_AuthenticationService.isLoggedIn){
      this.UserName=_AuthenticationService.userName;
      this.userEmail=_AuthenticationService.userEmail;
      this.userPhone=_AuthenticationService.userPhone;
    }
   }  
  ngOnInit() {
    this._contactForm=this._FormBuilder.group({
      name: ['', Validators.required],
      email:['', [Validators.required, Validators.email]],
      phone_number:['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(12)]],
      subject:['', Validators.required],
      message:['', Validators.required],
      agree: [false]  
    });
    

  }
  get f() { return this._contactForm.controls; }
  onSubmit(){
    this._submitted = true;
    this.reqMsg=true;
    if (this._contactForm.invalid) {
      return;
    }
       
    this._DataService.contactUs(this._contactForm.value).subscribe(
      res => {         
        this._status=res['status'];
        this._msg=res['message'];            
         if(this._status==1){              
          this.reqMsg=false; 
          this._contactForm.reset();
           return false;
         }     
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Contact. Because of Internal Server Error,Try again Later';                
      });
  }
}
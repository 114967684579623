import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Spinkit } from 'ng-http-loader';
import { UploadService } from 'src/app/_services/upload.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  public spinkit = Spinkit;
  _editProfileForm :FormGroup;
  _editProfilePictureForm :FormGroup;
  _submitted = false;
  _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  _userDaysData={};
  _userAvatar:any;
  maxDate: Date;
  _wantAgentShow='n';
  working_days=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thus' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ]; 
  _userData={
    name: '',
    phone_number: '',
    want_agent:'',
    civil_id: '',
    email: '',
    gender: '',
    dob: '',
    working_shift_timings: '',
    whatsapp_number: '',
    imo_number: '',
    paci_no:'',
    home_address: '',
    emergency_contact: '',
    office_address: '',
    reach_work: '',
    leave_work: '',
    trip:'',    
  }
  uploadResponse;
  user_id:number;
  temp_whtapp_number:string;
  temp_imo_number:string;
  temp_civil_id:string;
  constructor(    
    private _formBuilder: FormBuilder,
    private _UserService:UserService,
    private _Router:Router,
    private http: HttpClient,
    private uploadService:UploadService, 
    private _AuthenticationService:AuthenticationService,    
    public _UserValidation:UserValidationService,
  ) {
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);    
  }
  ngOnInit() {   
    this.user_id=this._AuthenticationService.userId;   
    this._editProfileForm = this._formBuilder.group({
      name: ['', [ Validators.required,Validators.pattern]],
      phone_number: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],     

      civil_id: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      gender: ['Male', Validators.required],
      dob: ['', Validators.required],
      whatsapp_number: ['', [ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      imo_number: ['', [ Validators.required,Validators.minLength(8),Validators.maxLength(20)]],
      paci_no: ['', Validators.required],
      home_address: ['', Validators.required],
      emergency_contact: ['', Validators.required],
      office_address: ['', Validators.required],
      reach_work: ['', Validators.required],
      leave_work: ['', Validators.required],
      trip: ['One way'],     
      working_shift_timings:['Morning'],
      want_agent:['n', Validators.required],
     working_days: new FormArray([
        new FormControl('Sun'),
        new FormControl('Mon'),
        new FormControl('Tues'),
        new FormControl('Wed'),
        new FormControl('Thus'),
        new FormControl(''),
        new FormControl(''),        
      ]),
    });
    this._editProfilePictureForm = this._formBuilder.group({    
      avatar: ['', Validators.required],
      user_id:['', Validators.required],
    });    
    this._UserService.getUserProfile().subscribe(dat=>{      
      this._userData=dat['data'];
      this.temp_whtapp_number= dat['data']['phone_number'];
      this.temp_imo_number= dat['data']['phone_number'];
      this._wantAgentShow=dat['data']['want_agent'] 
      this._userDaysData=dat['data']['working_days'];   
      this.temp_civil_id= dat['data']['civil_id'];   
    },
    error => {        
      console.log(error);     
    });   
    this._UserService.getUserAvatar().subscribe(res=>{
      this._userAvatar=res['data'];
    },error => {        
      console.log(error);     
    });    
  }
  get f() { return this._editProfileForm.controls; }
  onSubmit() {    
    this._submitted = true;    
    
    if (this._editProfileForm.invalid) {
      return;
    }    
    const selectedWorkingDays = this._editProfileForm.value.working_days
      .map((v, i) => v ? this.working_days[i].name : null)
      .filter(v => v !== null);    
    this._editProfileForm.value.working_days=selectedWorkingDays;   
    this._UserService.updateUserProfile(this._editProfileForm.value).subscribe(
      res => {          
        this._status=res['status'];
        this._msg=res['message'];    
        if(this._status===1){
          this._Router.navigate(['user/']);
        }
      },
      error => {        
        console.log(error); 
        this._status=0;
        this._msg='Unable to update. Because of Internal Server Error,Try again Later';              
      }
    );      
  }  
  
  public imagePath;
  imgURL: any;
  imgURLSUCC='./assets/images/dummy-profile-pic.png';
  public message: string;  
   onFileSelect(event) {
    if (event.target.files.length === 0)
      return; 
    var mimeType =  event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    } 
    var reader = new FileReader();
    this.imagePath =  event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._editProfilePictureForm.get('avatar').setValue(file);      
    }
  } 
  onSubmitImage() {    
    const formData = new FormData();
    formData.append('avatar', this._editProfilePictureForm.get('avatar').value);
    formData.append('user_id',this._editProfilePictureForm.get('user_id').value);
    this.uploadService.uploadFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;          
          this._status1=res['status'];
          this._msg1=res['message'];
          if(this._status1==1){
            this.imgURLSUCC=this.imgURL;           
          }          
      },
      (err) => {  
        console.log(err);
        this._status1=0;
        this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
      }
    );
  }
}
import { Component, OnInit } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-corporate-vendor-vehicles',
  templateUrl: './corporate-vendor-vehicles.component.html',
  styleUrls: ['./corporate-vendor-vehicles.component.css']
})
export class CorporateVendorVehiclesComponent implements OnInit {
  public spinkit = Spinkit;
  _corporatevehicleData:any;
  vehicle_path:string;
  _corporatevehicleLength:number;
  dtUsers =[
    {"id": 101, "firstName": "Anil", "lastName": "Singh"},
    {"id": 102, "firstName": "Reena", "lastName": "Singh"},
    {"id": 103, "firstName": "Aradhay", "lastName": "Simgh"},
    {"id": 104, "firstName": "Dilip", "lastName": "Singh"},
    {"id": 105, "firstName": "Alok", "lastName": "Singh"},
    {"id": 106, "firstName": "Sunil", "lastName": "Singh"},
    {"id": 107, "firstName": "Sushil", "lastName": "Singh"},
    {"id": 108, "firstName": "Sheo", "lastName": "Shan"},
    {"id": 109, "firstName": "Niranjan", "lastName": "R"},
    {"id": 110, "firstName": "Lopa", "lastName": "Mudra"},
    {"id": 111, "firstName": "Paramanand","lastName": "Tripathi"}
  ];
  constructor( private _CorporateVendorService:CorporateVendorService,) { }

  ngOnInit() {    
    this._CorporateVendorService.getCorporateVendorVehicles().subscribe(dat=>{      
      this._corporatevehicleData=dat['data']['corporate_vehicle'];
      this.vehicle_path=dat['data']['vehicle_path']; 
      this._corporatevehicleLength=dat['data']['corporate_vehicle'].length;
    },
    error => {        
      console.log(error);     
    }); 
  }
}
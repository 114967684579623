import { Component, OnInit } from '@angular/core';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
//import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-individual-user-bookings', 
  templateUrl: './individual-user-bookings.component.html',
  styleUrls: ['./individual-user-bookings.component.css']
})
export class IndividualUserBookingsComponent implements OnInit {
  oneWayTrip:any;
  _inboundDriverRatingForm :FormGroup;
  _outboundDriverRatingForm :FormGroup;
//  public spinkit = Spinkit;
driver_id_in=150;
vehiclePath:string;
  constructor(
    private _UserService:UserService,
    private _formBuilder: FormBuilder, 
  ) { }

  ngOnInit() {
    this._inboundDriverRatingForm = this._formBuilder.group({
      star: ['', Validators.required],
      comment: ['', Validators.required],     
       
    });
    this._outboundDriverRatingForm = this._formBuilder.group({
      star: ['', Validators.required],
      comment: ['', Validators.required],  
        
    });    
    this.getMySaveTrip();
    
  }
  getMySaveTrip(){
    this._UserService.getbooking().subscribe(res=>{
      this.oneWayTrip=res['data']['inbound_booking'];
      this.vehiclePath=res['data']['vehicle_path'];
     
    },error => {        
      console.log(error);     
    }); 
  }
 
  onInboundDriverRatingSubmit(driver_trip_id,booking_id,trip_id,driver_id) {
    let data={};
    data['booking_id']=booking_id;
    data['trip_id']=trip_id;
    data['driver_id']=driver_id;
    data['driver_trip_id']=driver_trip_id;
    data['rating']=this._inboundDriverRatingForm.value.star;
    data['comments']=this._inboundDriverRatingForm.value.comment;  
    this._UserService.setComments(data).subscribe(res=>{      
      if(res['status']==1){
        this.getMySaveTrip();
      }     
    },error => {        
      console.log(error);     
    }); 
    
  }
  onOutboundDriverRatingSubmit(driver_trip_id,booking_id,trip_id,driver_id) {
    let data={};
    data['booking_id']=booking_id;
    data['trip_id']=trip_id;
    data['driver_id']=driver_id;
    data['driver_trip_id']=driver_trip_id;
    data['rating']=this._inboundDriverRatingForm.value.star;
    data['comments']=this._inboundDriverRatingForm.value.comment;  
    this._UserService.setComments(data).subscribe(res=>{      
      if(res['status']==1){
        this.getMySaveTrip();
      }
     
    },error => {        
      console.log(error);     
    }); 
  }
  one_way_trip:any;
  round_way_trip:any;
  vehicle_path:string;
  outStatus:boolean=false;
  viewBooking(booking_id){   
    this._UserService.getBookingDetails(booking_id).subscribe(res=>{      
      this.one_way_trip=res['data']['one_way_trip'];
      this.round_way_trip=res['data']['round_way_trip'];
      this.vehicle_path=res['data']['vehicle_path'];     
      if(this.round_way_trip.length==0){
        this.outStatus=false;
      }else{
        this.outStatus=true;
      }     
    },error => {        
      console.log(error);     
    }); 
  }
 
  calculateHours(startTime,endTime){    
    let startDate:any = new Date("January 1, 1970 " + startTime);
    let endDate:any = new Date("January 1, 1970 " + endTime);
    let  timeDiff = Math.abs(startDate - endDate);
    var hh:any = Math.floor(timeDiff / 1000 / 60 / 60);
    if(hh < 10) {
      hh = '0' + hh;
    }
    timeDiff -= hh * 1000 * 60 * 60;
    var mm:any = Math.floor(timeDiff / 1000 / 60);
    if(mm < 10) {
      mm = '0' + mm;
    }
    timeDiff -= mm * 1000 * 60;
    var ss:any = Math.floor(timeDiff / 1000);
    if(ss < 10) {
      ss = '0' + ss;
    }   
    return hh;
  }
  calculateMinutes(startTime,endTime){    
    let startDate:any = new Date("January 1, 1970 " + startTime);
    let endDate:any = new Date("January 1, 1970 " + endTime);
    let  timeDiff = Math.abs(startDate - endDate);
    var hh:any = Math.floor(timeDiff / 1000 / 60 / 60);
    if(hh < 10) {
      hh = '0' + hh;
    }
    timeDiff -= hh * 1000 * 60 * 60;
    var mm:any = Math.floor(timeDiff / 1000 / 60);
    if(mm < 10) {
      mm = '0' + mm;
    }
    timeDiff -= mm * 1000 * 60;
    var ss:any = Math.floor(timeDiff / 1000);
    if(ss < 10) {
      ss = '0' + ss;
    }    
    return mm;
  }
}
import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { DriverService } from 'src/app/_services/driver.service';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { UploadService } from 'src/app/_services/upload.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-individual-vendor-profile',
  templateUrl: './individual-vendor-profile.component.html',
  styleUrls: ['./individual-vendor-profile.component.css']
})
export class IndividualVendorProfileComponent implements OnInit {
  public spinkit = Spinkit;
  _editProfileForm :FormGroup;
  _editProfilePictureForm :FormGroup;
  _submitted = false;
  _showRental = 'n';
  _driverAvatar:any; 
 _wantAgentShow='n';
  _driverData={
    name: '',
    phone_number: '',   
    civil_id: '',
    email: '',
    gender: '',
    dob: '',
    company_exit: '',
    whatsapp_number: '',
    imo_number: '',
    driver_nationality:'',
    company_name: '',
    license_number: '',
    license_expiry: '',
    rent_vehicle: '',
    company_address: '',
    block_number: '',
    street_number: '',
    building_number: '',
    no_of_vehicles:'',
    company_type_id:'',
    vender_id:'',
    want_agent:'',    
  };
  _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  rent_days=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thus' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ];
  maxDate: Date;
  uploadResponse;
  user_id:number;
  _driverDataRentDays:string;
  temp_whtapp_number:string;
  temp_imo_number:string;
  constructor(
    private _formBuilder: FormBuilder,
    private _DriverService:DriverService,
    private _Router:Router,    
    private uploadService:UploadService, 
    private _AuthenticationService:AuthenticationService,
    public _UserValidation:UserValidationService,
  ) { 
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 0);
  }

  ngOnInit() {
    this.user_id=this._AuthenticationService.userId;
    this._editProfileForm = this._formBuilder.group({
      name: ['', [ Validators.required,Validators.pattern]],
      phone_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      civil_id: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      gender: ['Male', Validators.required],
      dob: ['', Validators.required],
      whatsapp_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(20)]],
      imo_number: ['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(20)]],
      driver_nationality: ['', Validators.required],
      company_name: ['',],
      license_number: ['', [ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      license_expiry: ['', Validators.required],
      rent_vehicle: ['n', Validators.required],
      //rent_days: ['n', Validators.required],
      //rental_timings: ['', Validators.required],
      want_agent:['n',Validators.required],
      company_address: ['',],
      block_number: ['',],
      street_number: ['',],
      building_number: ['',],
      no_of_vehicles: ['1',], 
      company_type_id: ['1'],
      vender_id: ['1'],
      rent_days: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),        
      ]),   
      rental_timings: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),        
      ]),  
      rental_timings_end: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),        
      ]),    
       
    });      
      
    this._editProfilePictureForm = this._formBuilder.group({
      avatar: ['', Validators.required],
      user_id: ['', Validators.required],
    });       
    // get Driver Profile 
    this._DriverService.getDriverProfile().subscribe(dat=>{      
      this._driverData=dat['data'];     
      this.temp_whtapp_number= dat['data']['phone_number'];
      this.temp_imo_number= dat['data']['phone_number'];
      this._driverDataRentDays=this.trimming_fn(dat['data']['rent_days']);
      //this._userDaysData=dat['data']['working_days'] 
      this._wantAgentShow=dat['data']['want_agent'] ;      
    },
    error => {        
      console.log(error);     
    });
    // get User Avatar 
    this._DriverService.getDriverAvatar().subscribe(res=>{
      this._driverAvatar=res['data'];
    },error => {        
      console.log(error);     
    }); 
  }
  get f() { return this._editProfileForm.controls; }
  showRental(showRental) {    
    this._showRental = showRental;
  }
  onSubmit() {    
    this._submitted = true;
    if (this._editProfileForm.invalid) {
      return;
    }    
    // const selectedrentDays = this._editProfileForm.value.rent_days
    //   .map((v, i) => v ? this.rent_days[i].name : null)
    //   .filter(v => v !== null);    
    const selectedrentDays = this._editProfileForm.value.rent_days
    .map((v, i) => v ? this.rent_days[i].name : ' ')
    .filter(v =>v);  
    this._editProfileForm.value.rent_days=selectedrentDays;      
    this._DriverService.updateDriverProfile(this._editProfileForm.value).subscribe(
      res => {          
        this._status=res['status'];
        this._msg=res['message'];
        if(this._status==1){
          this._Router.navigate(['indv-vendor']);
        }
        
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Update. Because of Internal Server Error,Try again Later';              
      });
  }
  public imagePath;
  imgURL: any;
  //imgURLSUCC:any;  
  imgURLSUCC='./assets/images/dummy-profile-pic.png';
  public message: string;
  PictureValid:boolean=true;
   // Image uploading
   onFileSelect(event) {
    this.PictureValid=false;
    this.message ='';
    if (event.target.files.length === 0)
      return; 
    var mimeType =  event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.PictureValid=true;
      this.message = "Only images are supported.";
      return;
    } 
    var reader = new FileReader();
    this.imagePath =  event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._editProfilePictureForm.get('avatar').setValue(file);      
    }
  }
 
  onSubmitImage() {    
    const formData = new FormData();
    formData.append('avatar', this._editProfilePictureForm.get('avatar').value);
    formData.append('user_id',this._editProfilePictureForm.get('user_id').value);
    this.uploadService.uploadDriverFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;          
          this._status1=res['status'];
          this._msg1=res['message'];
          if(this._status1==1){
            this.imgURLSUCC=this.imgURL;           
          }          
      },
      (err) => {  
        console.log(err);
        this._status1=0;
        this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
      }
    );
  }
  // this will remove whole whitespace from the string    
  trimming_fn(x) {    
    return x ? x.replace(/\s/g,'') : '';    
  };  
  
  keyUPCivil(event: any){   
    this._driverData.license_number=event.target.value;
  }

} 

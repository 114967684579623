import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { DataService } from 'src/app/_services/data.service';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-edit-vehicle',
  templateUrl: './corporate-vendor-edit-vehicle.component.html',
  styleUrls: ['./corporate-vendor-edit-vehicle.component.css']
})
export class CorporateVendorEditVehicleComponent implements OnInit {
  public spinkit = Spinkit; 
  _VehicleTypes=[];
  _status:number;
  _msg:string;
  _editVehicleForm :FormGroup;
  _submitted = false;
  vehicleData={
    'vehicle_id':'',
    'vehicle_type_id':'',
    'vehicle_make':'',
    'vehicle_model':'',
    'vehicle_seating_capacity':'',
    'ownership_type':'',
    'service_type':'',
    'traffic_plate_number':'',
    'dafter_details':'',    
  }
  vehicle_path:string;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _DataService:DataService,
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this._editVehicleForm = this._formBuilder.group({
      vehicle_type_id: ['1', Validators.required],
      vehicle_make: ['', Validators.required],
      vehicle_model: ['', Validators.required],
      vehicle_seating_capacity: ['', Validators.required],
      ownership_type: ['own', Validators.required],
      service_type: ['regular', Validators.required],
      traffic_plate_number: ['', Validators.required],
      dafter_details: ['', Validators.required],
      vehicle_id: ['', Validators.required],
    });
   
    this._DataService.getVehicleType().subscribe(res=>{
      this._VehicleTypes=res['data'];
    },error => {        
      console.log(error);     
    });
    
    const vehicle_id= this._ActivatedRoute.snapshot.params.id;    
    this._CorporateVendorService.getCorporateVendorVehicle(vehicle_id).subscribe(dat=>{      
      this.vehicleData=dat['data']['corporate_vehicle'];
      this.vehicle_path=dat['data']['vehicle_path']; 
      
    },
    error => {        
      console.log(error);     
    }); 

  }
  get f() { return this._editVehicleForm.controls; }
  onSubmit(){
    this._submitted = true;      
     if(this._editVehicleForm.invalid){
       return false;
     }      
    this._DataService.updateVehicle(this._editVehicleForm.value).subscribe(res=>{     
      this._status=res['status'];      
      this._msg=res['message'];
     },error => {        
       console.log(error);  
       this._status=0;
       this._msg='Unable to update. Because of Internal Server Error,Try again Later';   
     }); 
   }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../_services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  public spinkit = Spinkit;
  constructor(
    private _data:DataService,   
    private _Router:Router,
    public _AuthenticationService:AuthenticationService,
  ) { }
  customSilders: OwlOptions = {
    loop:true, dots: false, nav:true, navText:['', ''], items:1, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items: 1}, 400:{items: 1}, 740:{items: 1},940:{items: 1} }
  }
  customTestimonials: OwlOptions = {    
    loop:true, dots: false, nav:true, navText:['', ''], items:2, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:2}, 992:{items:2}, 1199:{items:2} }
  }  
  ngOnInit() {
  }
  redirectRegistration(type){    
    this._data.setUserTypeRole(type); 
    this._data.unsetUsergent(); 
    this._Router.navigate(['/register']);   
  }
  wantAgent(type,agent){
    this._data.setUserTypeRole(type);   
    this._data.setUsergent(agent);   
    this._Router.navigate(['/register']);   
  }
  _status:number;
  _msg:string;
  isShown:boolean=true;
  BecomeAsAgent(agent){
    this._data._BecomeAsAgent(agent).subscribe(res=>{        
      this._status=res['status'];
      this._msg=res['message'];  
      if(this._status==1){
        this.isShown=false;
      }        
    });   
  }
}
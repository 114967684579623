import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-edit-route-trip',
  templateUrl: './corporate-vendor-edit-route-trip.component.html',
  styleUrls: ['./corporate-vendor-edit-route-trip.component.css']
})
export class CorporateVendorEditRouteTripComponent implements OnInit {
  public spinkit = Spinkit;
  _editVehicleProfileForm :FormGroup;
  _submitted = false;
  _routes=[];
  _Route:any;
  _status:number;
  _msg:string;
  days_trip=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thus' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ];
 
  _Trip={
    trip_id:'',
    route_id:'',
    start_point:'',
    end_point:'',
    start_time:'',
    end_time:'',
    nature_trip:'',
    days_trip:'', 
  }
  r_route_id:string;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,  
    private _ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this._editVehicleProfileForm = this._formBuilder.group({
      route_id: ['', Validators.required],
      start_point: ['', Validators.required],
      end_point: ['', Validators.required],
      start_time: ['00:00:00', Validators.required],
      end_time: ['00:00:00', Validators.required],
      nature_trip: ['inbound', Validators.required], 
      trip_id:['', Validators.required],
      days_trip: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),        
      ]),         
    });     
    this._CorporateVendorService.getTripRouts().subscribe(res=>{
      this._routes=res['data'];
    },error => {        
      console.log(error);     
    });
    const trip_id= this._ActivatedRoute.snapshot.params.id;  
    this._CorporateVendorService.getTrip(trip_id).subscribe(res=>{
      this._Trip=res['data'];      
      this.r_route_id= this._Trip.route_id;    
      this.getRoutesTrip(this.r_route_id); 
      
    },error => {        
      console.log(error);     
    });    
   
  }
  s_point:string='';
  e_point:string='';
  inoutTime:string='inbound';
  getLocation(evt){
    let selectedIndex:number = evt.target["selectedIndex"];
    let location_a=evt.target.options[selectedIndex].getAttribute("data-location_a");
    let location_b=evt.target.options[selectedIndex].getAttribute("data-location_b");    
    this._Trip.start_point=location_a;
    this._Trip.end_point=location_b;
      
  }
  changeLocation(){   
    let a_point=this._Trip.start_point;
    let b_point=this._Trip.end_point;
    this._Trip.start_point=b_point;     
    this._Trip.end_point=a_point;
    let Nature=this._editVehicleProfileForm.value;
    this.inoutTime=Nature.nature_trip;    
  }
  onSubmit(){
    this._submitted = true;
     if(this._editVehicleProfileForm.invalid){
       return false;
     }         
    const selectedrentDays = this._editVehicleProfileForm.value.days_trip
    .map((v, i) => v ? this.days_trip[i].name : ' ')
    .filter(v =>v);  
    this._editVehicleProfileForm.value.days_trip=selectedrentDays;    
     this._CorporateVendorService.UpadateTrip(this._editVehicleProfileForm.value).subscribe(res=>{     
       this._status=res['status'];  
       this._msg=res['message'];      
       if(this._status==1){
         this._Router.navigate(['/corp-vendor/route-trips/'+this.r_route_id]);  
       } 
     },error => {        
       console.log(error);  
       this._status=0;
       this._msg='Unable to upadte trip. Because of Internal Server Error,Try again Later';
     }); 
   }
   getRoutesTrip(route_id){        
    this._CorporateVendorService.getRoutesTrip(route_id).subscribe(res=>{     
      this._Route=res['data']['route']; 
    },error => {        
      console.log(error);     
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../_services/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.css']
})
export class CorporateComponent implements OnInit {
  public spinkit = Spinkit;
  constructor(
    private _data:DataService,   
    private _Router:Router,
  ) { }

  customSilders: OwlOptions = {
    loop:true, dots: false, nav:true, navText:['', ''], items:1, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items: 1}, 400:{items: 1}, 740:{items: 1},940:{items: 1} }
  }
  customTestimonials: OwlOptions = {    
    loop:true, dots: false, nav:true, navText:['', ''], items:2, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:2}, 992:{items:2}, 1199:{items:2} }
  }
  customCorporateClients: OwlOptions = {    
    loop:true, dots: false, nav:false, items:5, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:2}, 768:{items:4}, 992:{items:5}, 1199:{items:5} }
  }
  
  ngOnInit() {
  }
  redirectRegistration(type){
    this._data.setUserTypeRole(type);   
    this._Router.navigate(['/register']);   
  }
}

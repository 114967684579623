import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder,FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CorporateUserService } from 'src/app/_services/corporate-user.service';
import { Spinkit } from 'ng-http-loader';
import { DataService } from 'src/app/_services/data.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';

@Component({
  selector: 'app-corporate-user-request-quote',
  templateUrl: './corporate-user-request-quote.component.html',
  styleUrls: ['./corporate-user-request-quote.component.css']
})
export class CorporateUserRequestQuoteComponent implements OnInit {
	public spinkit = Spinkit;
	_corporateuserData:any;
  _submitted = false;
  minDate: Date;
  reqMsg:boolean=false;
  _status:number;
  _msg:string;
  _VehiclesTypes:any;
  phone:string;
  working_days=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thur' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ]; 
  constructor(
    private _formBuilder: FormBuilder, 
    private _CorporateUserService:CorporateUserService,
    private _Router:Router,
    private _DataService :DataService,  
    public _UserValidation:UserValidationService, 
    private _fb: FormBuilder,
  ) { 
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
  }

  ngOnInit() {   
    this.phaseForm = this._fb.group({
      contact_person: ['',[ Validators.required,Validators.pattern]],
      contact_person_number: ['+965',[ Validators.required,Validators.pattern,Validators.minLength(12),Validators.maxLength(12)]],
      contact_person_email: ['', [Validators.required,Validators.email]],
      contact_time: ['', Validators.required],     
      end_contact_time: ['', Validators.required],
      phaseExecutions: this._fb.group({
        PRE: this._fb.array([this.addPhase()])
      })
    });
     
     this._DataService._getVehiclesTypes().subscribe(res=>{
      this._VehiclesTypes=res['data'];      
    });      
	
	this._CorporateUserService.getCorporateUserProfile().subscribe(dat=>{      
      this._corporateuserData=dat['data']['corporate_user']; 
		 if(dat['data']['corporate_user']['contact_phone']){
			this.phone=dat['data']['corporate_user']['contact_phone'];
		}  
    }); 
	
	
	
	
	
	
  }  
  phaseForm: FormGroup;
  addPhase() {
    return this._fb.group({    
      fuel_type:  ['1', Validators.required],
      driver_type: ['1', Validators.required],
      shift_type: ['Morning',Validators.required],
      start_date: ['',Validators.required],
      end_date: ['', ],
      start_location: ['',Validators.required],
      end_location: ['', Validators.required],
      from_day: ['Sunday',Validators.required],
      to_days: ['Thursday', Validators.required],
      start_time: ['',Validators.required],
      end_time: ['', Validators.required],
      vehicle_type_id:['', Validators.required],
      period_time:['30',Validators.required],
      no_of_employees: ['', Validators.required],
    });
  }

  addMorePhase() {
    this.phaseArray.push(this.addPhase());
  }
  removeMorePhase(i: number){
    const control = <FormArray>(<FormGroup>this.phaseForm.get('phaseExecutions')).get('PRE');
    control.removeAt(i);
  }  
  messageInvalid:boolean;  
  get phaseArray() {
    const control = <FormArray>(<FormGroup>this.phaseForm.get('phaseExecutions')).get('PRE');
    return control;
  }
  onMultiSubmit(){
    this._submitted = true;
    this.reqMsg=true;       
    this.messageInvalid=false;
    if (this.phaseForm.invalid) {
      this.messageInvalid=true;
      return;
    }      
    this._CorporateUserService.addMultiRequest(this.phaseForm.value).subscribe(
      res => {           
        this._status=res['status'];
        this._msg=res['message'];            
        if(this._status==1){
          this._Router.navigate(['/corp-user']);   
          this.reqMsg=false; 
          this.phaseForm.reset();
          return false;
        }            
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to send Request. Because of Internal Server Error,Try again Later';
              
      });

  }  
  
}
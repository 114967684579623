import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UrlApiService } from '../_services/url-api.service';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  httpOptions:any;
  _searchData:any;
  _driverId:any;
  _userType:string='';
  _userAgent:string='';
  constructor(
    private _Http:HttpClient,
    private _urlApi:UrlApiService,
    private _AuthenticationService:AuthenticationService,

  ) { 
    this.httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json; charset=utf-8',     
        
        })
    }
  }

  _getRoles(){
    let _url=this._urlApi._getUrl();
    return this._Http.get(_url+'roles');
  }
  _getVehicleRoutes (){
    let _url=this._urlApi._getUrl();
    return this._Http.get(_url+'vehicle_routes');   
  }
  _getLocationPoints(){
    let _url=this._urlApi._getUrl();    
    return this._Http.get(_url+'location_points');
  }
  _getVehicle(){
    let _url=this._urlApi._getUrl();    
    return this._Http.get(_url+'vehicles');
  }
  _setSearchData(_data:any){
    this._searchData=_data;    
  }
  _getSearchData(){ 
    return  this._searchData;
  }
  _getSearchTripDriver(search){    
    let _url=this._urlApi._getUrl();   
    return this._Http.post(_url+'search_trip_driver', search,this.httpOptions);
  }
  getTripDriverDetails(driver_trip_id){   
    let user_id=this._AuthenticationService.userId;
    let _url=this._urlApi._getUrl();   
    let data2={};
    data2['driver_trip_id']=driver_trip_id;    
    data2['user_id']=user_id;    
    return this._Http.post(_url+'get_trip_driver_details',data2,this.httpOptions);
  }
  chnageDriverTrip(driver_trip_id){
    let _url=this._urlApi._getUrl();   
    let data2={};
    data2['driver_trip_id']=driver_trip_id;    
    return this._Http.post(_url+'get_change_driver_trip',data2,this.httpOptions);
  }
  
  setDriveId(_data:any){
    this._driverId=_data;    
  }
  _getDriveId(){ 
    return  this._driverId;
  }
  getSelectedTripDriverDetails(data2:any){    
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'get_selected_trip_driver_details',data2,this.httpOptions);
  }
  saveFavoritesRoute(data2:any){    
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'favorites_routes',data2,this.httpOptions);
  }

  _getGovernorates(){
    let _url=this._urlApi._getUrl();    
    return this._Http.get(_url+'governorates');
  }
  _getGovernorateCities(governorate_id){
    let _url=this._urlApi._getUrl();   
    let data={
      'governorate_id':governorate_id,
    }; 
    return this._Http.post(_url+'governorate_cities',data,this.httpOptions);
  }  
  getGovernorateCityName(governorate_city){
    let _url=this._urlApi._getUrl();   
    let data={
      'governorate_city':governorate_city,
    }; 
    return this._Http.post(_url+'governorate_cities_routes',data,this.httpOptions);
  }
  _getVehiclesTypes(){
    let _url=this._urlApi._getUrl();    
    return this._Http.get(_url+'vehicles_type');
  }
  VerifyEmail(_code){
    let _url=this._urlApi._getUrl();   
    let data={
      'verify_code':_code,
    };     
    return this._Http.post(_url+'verify_code',data,this.httpOptions);
  }
  forgotPassword(data){    
    let _url=this._urlApi._getUrl();        
    return this._Http.post(_url+'forgot_password',data,this.httpOptions);
  }
  getVehicleType(){
    let _url=this._urlApi._getUrl();
    return this._Http.get(_url+'vehicles_type');
  }
  updateVehicle(data){
    let _url=this._urlApi._getUrl();        
    return this._Http.post(_url+'update_corporate_vehicle',data,this.httpOptions);
  }
  getDestinationTypes(){
    let _url=this._urlApi._getUrl();
    return this._Http.get(_url+'destination_types');
  }
  getComments(trip_id,driver_id){
    let _url=this._urlApi._getUrl();   
    let data={
      'driver_id':driver_id,
      'trip_id':trip_id,
    };     
    return this._Http.post(_url+'comments',data,this.httpOptions);
  }
  setUserTypeRole(user_type){
    this._userType=user_type;
  }
  getUserTypeRole():string{
    return  this._userType;
  }
  unSetUserTypeRole():string{
    this._userType='';
    return  this._userType;
  }
  setUsergent(agent){
    this._userAgent=agent;
  }
  getUsergent():string{
    return  this._userAgent;
  }
  unsetUsergent():string{
    this._userAgent='';
    return  this._userAgent;
  }
  contactUs(data){
    let _url=this._urlApi._getUrl();      
    return this._Http.post(_url+'contact_us',data,this.httpOptions);
  }
  RequestQuote(data){
    let user_id=this._AuthenticationService.userId;
    data['user_id']=user_id;
    let _url=this._urlApi._getUrl();      
    return this._Http.post(_url+'fleet_quote',data,this.httpOptions);
  }
  _BecomeAsAgent(want_agent){
    let user_id=this._AuthenticationService.userId;   
    let data={
      'user_id':user_id,
      'want_agent':want_agent,
    }; 
    let _url=this._urlApi._getUrl();      
    return this._Http.post(_url+'become_agent',data,this.httpOptions);
  }
  isAgent(user_id){    
    let data={
      'user_id':user_id,     
    }; 
    let _url=this._urlApi._getUrl();      
    return this._Http.post(_url+'get_user',data,this.httpOptions);
  }
  AgentRequest(){
    let user_id=this._AuthenticationService.userId;   
    let data={
      'user_id':user_id,
      'want_agent':'y',
    }; 
    let _url=this._urlApi._getUrl();      
    return this._Http.post(_url+'become_agent',data,this.httpOptions);
  }
}  
import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  public spinkit = Spinkit;
  constructor() { }
  ngOnInit() {
  }
}
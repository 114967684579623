import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from '../_models/user';
import { UrlApiService } from './url-api.service';

import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  httpOptions:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    public afAuth: AngularFireAuth,     
   
  ) { 
    this.httpOptions = { 
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json; charset=utf-8',     
        
        })
    }
  }
  register(user: User) {    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'user-registration', user,this.httpOptions);
  }
  doRegisterWithFireBase(data){    
    return this.afAuth.auth.createUserWithEmailAndPassword(data.email, data.password)
    .then((newUser) => {

      let record={  
        'id': newUser.user.uid,    
        'email':data.email,
        'imageURL':'default',
        'mobile:':data.phone_number,
        'status':'offline',
        'username':data.name,
      }
     
      firebase.database()
      .ref(`/Users`)
      .child(newUser.user.uid)
      .set(record);


    }).catch((error) => {
      
    })

  }

 
}
 
import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-vendor-trips',
  templateUrl: './corporate-vendor-trips.component.html',
  styleUrls: ['./corporate-vendor-trips.component.css']
})
export class CorporateVendorTripsComponent implements OnInit {
  public spinkit = Spinkit;
  _Trips:any;
  _TripsLength:number;
  constructor( 
    private _CorporateVendorService:CorporateVendorService,
    public _UserValidation:UserValidationService,  
    ) { }
  ngOnInit() {
    
    this._CorporateVendorService.getTrips().subscribe(res=>{
      this._Trips=res['data'];
      this._TripsLength=res['data'].length;
    },error => {        
      console.log(error);     
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DriverService } from 'src/app/_services/driver.service';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-individual-vendor-routes',
  templateUrl: './individual-vendor-routes.component.html',
  styleUrls: ['./individual-vendor-routes.component.css']
})
export class IndividualVendorRoutesComponent implements OnInit {
  constructor(private _DriverService:DriverService,) { }
  _Routes:any;
  public spinkit = Spinkit;
  _RoutesLength:number;
  ngOnInit() {
    this._DriverService.getDriverAssignRoutes().subscribe(res=>{
      this._Routes=res['data']['area_route'];
      this._RoutesLength=res['data']['area_route'].length;
    });
  }
  
}
 
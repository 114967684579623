import { Component, OnInit } from '@angular/core';
import { SlickModule } from 'ngx-slick';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { DriverService } from 'src/app/_services/driver.service';
import { Spinkit } from 'ng-http-loader';
import { UploadService } from 'src/app/_services/upload.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-individual-vendor-vehicle',
  templateUrl: './individual-vendor-vehicle.component.html',
  styleUrls: ['./individual-vendor-vehicle.component.css']
})
export class IndividualVendorVehicleComponent implements OnInit {
  _editVechilePictureForm :FormGroup;
  _addVechileGalleryForm :FormGroup;
  constructor(
    private _DriverService:DriverService,
    private _formBuilder: FormBuilder,
    private uploadService:UploadService, 
    private _Router:Router,
    ) { }
  _assignVehicle:any;
  _VehicleGallery:any;  
  _VehiclePath:string;  
  _vehicle_id:number;
  public spinkit = Spinkit;   
  slideConfig={};
  slideConfigThumb={};
  ngOnInit() {
    this._editVechilePictureForm = this._formBuilder.group({    
      avatar: ['', Validators.required],
      vehicle_id:['', Validators.required],
    });  
    this._addVechileGalleryForm = this._formBuilder.group({    
      avatar: ['', Validators.required],
      vehicle_id:['', Validators.required],
    }); 
    this.slideConfig = {
      slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      arrows: false, fade: true, asNavFor: '.slider-nav'
    };
    this.slideConfigThumb = {
      slidesToShow: 3, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      dots: false, centerMode: true, focusOnSelect: true, asNavFor: '.slider-for'
    };
    
     this._DriverService.getDriverAssignVehicle().subscribe(res=>{
      this._assignVehicle=res['data']['vehicle']; 
      this._vehicle_id=res['data']['vehicle']['vehicle_id'];  
      this._VehicleGallery=res['data']['vehicle_gallery'];  
      this._VehiclePath=res['data']['vehicle_path'];  
      if(this._vehicle_id==undefined){this._vehicle_id=0; }   
     
    },error => {        
      console.log(error);     
    });
  }

   
   public imagePath;
   imgURLVCHL: any;
   imgURL: any;
   imgURLSUCC:any;
   public message: string; 
   public message1: string; 
   _status:number;
  _msg:string;
  _status1:number;
  _msg1:string;
  PictureValid:boolean=true;
  PictureValid1:boolean=true;
    onFileSelect(event) {
      this.PictureValid=false;
      this.message ='';
     if (event.target.files.length === 0)
       return; 
     var mimeType =  event.target.files[0].type;
     if (mimeType.match(/image\/*/) == null) {
        this.PictureValid=true;
        this.message = "Only images are supported.";
        return;
     } 
     var reader = new FileReader();
     this.imagePath =  event.target.files;
     reader.readAsDataURL(event.target.files[0]); 
     reader.onload = (_event) => { 
       this.imgURLVCHL = reader.result;      
     }
     if (event.target.files.length > 0) {
       const file = event.target.files[0];
       this._editVechilePictureForm.get('avatar').setValue(file);      
     }
   }
  
   onSubmitImage() {    
     const formData = new FormData();
     formData.append('avatar', this._editVechilePictureForm.get('avatar').value);
     formData.append('vehicle_id',this._editVechilePictureForm.get('vehicle_id').value);
     this.uploadService.uploadVehicleFile(formData).subscribe(
       (res) => {              
           this._status=res['status'];
           this._msg=res['message'];
           if(this._status==1){           
             this.imgURLSUCC=this.imgURLVCHL;                
           }          
       },
       (err) => {  
         console.log(err);
         this._status=0;
         this._msg='Unable to update. Because of Internal Server Error,Try again Later'; 
       }
     );
   }

   

   onFileSelectGallary(event) {
    this.PictureValid1=false;
    this.message1 ='';
    if (event.target.files.length === 0)
      return; 
    var mimeType =  event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.PictureValid1=true;
      this.message1 = "Only images are supported.";
      return;
    } 
    var reader = new FileReader();
    this.imagePath =  event.target.files;
    reader.readAsDataURL(event.target.files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result;      
    }
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._addVechileGalleryForm.get('avatar').setValue(file);      
    }
  }
  onSubmitImageGallery() {    
    const formData = new FormData();
    formData.append('avatar', this._addVechileGalleryForm.get('avatar').value);
    formData.append('vehicle_id',this._addVechileGalleryForm.get('vehicle_id').value);
    this.uploadService.uploadVehicleGalleryFile(formData).subscribe(
      (res) => {              
          this._status1=res['status'];
          this._msg1=res['message'];
          if(this._status1==1){
              
          }          
      },
      (err) => {  
        console.log(err);
        this._status1=0;
        this._msg1='Unable to update. Because of Internal Server Error,Try again Later'; 
      }
    );
  }

}
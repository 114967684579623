import { Component, OnInit } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';

@Component({
  selector: 'app-corporate-vendor-routes',
  templateUrl: './corporate-vendor-routes.component.html',
  styleUrls: ['./corporate-vendor-routes.component.css']
})
export class CorporateVendorRoutesComponent implements OnInit {
  public spinkit = Spinkit;
  _Routes:any;
  routeLength:number;
  constructor( private _CorporateVendorService:CorporateVendorService,) { }

  ngOnInit() {   
    this._CorporateVendorService.getRoutes().subscribe(res=>{
      this._Routes=res['data']['corporate_routes'];
      this.routeLength=res['data']['corporate_routes'].length;
    },error => {        
      console.log(error);     
    });
  }

}

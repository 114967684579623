import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { User } from '../_models/user';
import { UrlApiService } from './url-api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  httpOptions:any;
  httpOptions1:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    private _AuthenticationService:AuthenticationService,

  ) { 
    this.httpOptions = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/json; charset=utf-8',     
        
        })
    }
    this.httpOptions1 = {
      headers: new HttpHeaders(
        { 'Content-Type': 'multipart/form-data;',     
        
        })
    }    
  }  
  getUserProfile(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'profile', user,this.httpOptions);
  }
  getUserAvatar(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'profile_avatar', user,this.httpOptions);
  }
  updateUserProfile(user: User){   
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;   
    let working_days=user['working_days'].join(",");     
    user['working_days']=working_days;   
    let today = new Date();
    let dob=new Date(today.setFullYear(today.getFullYear()));
    let gdob=dob.toISOString().slice(0, 10);
    user['dob']=gdob;     
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'update_profile', user,this.httpOptions);
  }
  uploadUserAvatar(formData){     
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'web_upload_profile_avatar', formData,this.httpOptions1);
  }
  changePassword(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;    
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'change_password', user,this.httpOptions);

  }
  bookingTrip(data:any){
    let user_id=this._AuthenticationService.userId;
    data['user_id']=user_id;    
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'book_trip', data,this.httpOptions);
  }
  getFavoritesRoute(){
    let user_id=this._AuthenticationService.userId;
    let user={}
    user['user_id']=user_id;    
    let _url=this._urlApi._getUrl();    
     return this._Http.post(_url+'favourite_route', user,this.httpOptions);
   }
   getbooking(){
    let user_id=this._AuthenticationService.userId;
    let user={}
    user['user_id']=user_id;    
    let _url=this._urlApi._getUrl();    
     return this._Http.post(_url+'booking', user,this.httpOptions);
   }
  setComments(data){
    let user_id=this._AuthenticationService.userId;    
    data['user_id']=user_id;    
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'set_comments', data,this.httpOptions);
  }
  getBookingDetails(booking_id){    
    let user={}
    user['booking_id']=booking_id;    
    let _url=this._urlApi._getUrl();    
     return this._Http.post(_url+'get_booking_details', user,this.httpOptions);
  }
} 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup , FormBuilder,Validators} from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DataService } from 'src/app/_services/data.service';
import { NgSelect2Module } from 'ng-select2';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from '../../_services/authentication.service';
@Component({
  selector: 'app-rent-feet',
  templateUrl: './rent-feet.component.html',
  styleUrls: ['./rent-feet.component.css']
})
export class RentFeetComponent implements OnInit {
  public spinkit = Spinkit;
  _rentafleetForm :FormGroup;
  _Governorates:any;
  _VehiclesTypes:any;
  minDate: Date;
  __drivertype = '1';
  __fueltype = '1';
  UserName="";
  userEmail="";
  userPhone="+965";
  constructor(
    private _Router: Router,
    private _FormBuilder:FormBuilder,   
    private _DataService :DataService,   
    private _AuthenticationService:AuthenticationService,
  ) {
      if(_AuthenticationService.isLoggedIn){
        this.UserName=_AuthenticationService.userName;
        this.userEmail=_AuthenticationService.userEmail;
        this.userPhone=_AuthenticationService.userPhone;
      }
   
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 0);
   }
  customSilders: OwlOptions = {
    loop:false, dots: false, nav:true, navText:['', ''], items:1, autoplay:false, autoplayHoverPause:false, smartSpeed:1000,  
    responsive: { 0:{items: 1}, 400:{items: 1}, 740:{items: 1},940:{items: 1} }
  }
  customCities: OwlOptions = {    
    loop:true, dots: false, nav:false, items:5, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:2}, 768:{items:4}, 992:{items:6}, 1199:{items:6} }
  }
  customTestimonials: OwlOptions = {    
    loop:true, dots: false, nav:true, navText:['', ''], items:2, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:2}, 992:{items:2}, 1199:{items:2} }
  }  
  ngOnInit() {
    this._rentafleetForm=this._FormBuilder.group({
      vehicle_type_name:['',Validators.required],
      name:['',Validators.required],
      email:['', [Validators.required, Validators.email]],
      phone_number:['',[ Validators.required,Validators.pattern,Validators.minLength(8),Validators.maxLength(12)]],
      request_date:['',Validators.required],
      request_time:['',Validators.required],
      days:['0 Days',Validators.required],
      hours:['2 Hours',Validators.required],
      driver_type:['With Driver',Validators.required],
      fuel_type:['With Fuel',Validators.required],
    });
    // Get Governorates
    this._DataService._getGovernorates().subscribe(res=>{
      this._Governorates=res['data'];
    });
    // Get Vehicles Type
    this._DataService._getVehiclesTypes().subscribe(res=>{
      this._VehiclesTypes=res['data'];      
    });  
  }
  driverControls(drivertype) {    
    this.__drivertype = drivertype;
  }
  fuelControls(fueltype) {    
    this.__fueltype = fueltype;
  }
  _submitted = false;
  _status:number;
  _msg:string;
  reqMsg:boolean=false;
  get f() { return this._rentafleetForm.controls; }
  onSubmit(){
    this._submitted = true;  
    this.reqMsg=true;   
    // stop here if form is invalid
    if (this._rentafleetForm.invalid) {
      return;
    }     
    this._DataService.RequestQuote(this._rentafleetForm.value).subscribe(
      res => {        
        this._status=res['status'];
        this._msg=res['message']; 
        this.reqMsg=false; 
        this._rentafleetForm.reset();
        return false;                 
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Sent Fleet Request. Because of Internal Server Error,Try again Later';
        //this._userService.error(error);          
      });

  }

  Governorate:any;
  GovernorateCities:any;
  GovernorateCitieRoutes:any;
  GovernorateCitieRoutesLength:number;
  getGovernorateCity(governorate_id){    
    this._DataService._getGovernorateCities(governorate_id).subscribe(res=>{     
      this.Governorate=res['data']['Governorate'];
      this.GovernorateCities=res['data']['GovernorateCity'];      
    });    
  } 
  getGovernorateCityName(governorate_city){       
    this._DataService.getGovernorateCityName(governorate_city).subscribe(res=>{       
      this.GovernorateCitieRoutes=res['data'];
      this.GovernorateCitieRoutesLength=res['data'].length;
    });   
  }  
  SearchGovernorateCitie(location_a:string,location_b:string){
    let sdata={
      'nature_trip':'round',
      'leave_at':'',
      'return_at':'',
    };
    sdata['location_a']=location_a;
    sdata['location_b']=location_b;        
    this._DataService._setSearchData(sdata);   
    this._Router.navigate(['search']);
  }
}
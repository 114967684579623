import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor() { }
  customCorporate: OwlOptions = {    
    loop:true, dots: false, nav:false, items:1, margin:0, autoplay:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:1}, 992:{items:1}, 1199:{items:1} }
  }
  customRiders: OwlOptions = {    
    loop:true, dots: false, nav:false, items:1, margin:0, autoplay:true, smartSpeed:1000,  
    responsive: { 0:{items:1}, 768:{items:1}, 992:{items:1}, 1199:{items:1} }
  }
  
  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { UrlApiService } from './url-api.service';
import { AuthenticationService } from './authentication.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CorporateVendorService {
  httpOptions:any;
  constructor(
    private _urlApi:UrlApiService,
    private _Http:HttpClient,
    private _AuthenticationService:AuthenticationService,
    private datePipe: DatePipe,
  ) {
      this.httpOptions = {
        headers: new HttpHeaders(
          { 'Content-Type': 'application/json; charset=utf-8',          
          })
      }
   } 
   getCorporateVendorProfile(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_profile', user,this.httpOptions);
  }
  addVehicle(vehicles:any){
    let user_id=this._AuthenticationService.userId;    
    vehicles['user_id']=user_id; 
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'add_corporate_vehicles',vehicles,this.httpOptions);
  }
  updateCorporateVendorProfile(user:any){
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;  
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_profile_update',user,this.httpOptions);
  }
  addCorporateDriver(user:any){  
    let user_id=this._AuthenticationService.userId;
    user['user_id']=user_id;     
   
    let today =  user['dob'];
    user['dob']=this.datePipe.transform(today,"yyyy-MM-dd");     
    let today1 =  user['license_expiry'];
    user['license_expiry']=this.datePipe.transform(today1,"yyyy-MM-dd");     
    let _url=this._urlApi._getUrl(); 
    return this._Http.post(_url+'add_corporate_driver', user,this.httpOptions);    
  }
  getCorporateVendorDrivers(){
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_all_drivers', user,this.httpOptions);
  }
  getCorporateVendorVehicles(){ 
    let user_id=this._AuthenticationService.userId
    let user={
      'user_id':user_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_all_vehicle', user,this.httpOptions);
  }
  getCorporateVendorVehicle(vehicle_id){   
    let data={
      'vehicle_id':vehicle_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_vehicle', data,this.httpOptions);
  }  
  getCorporateDriver(corporate_driver_id){   
    let data={
      'corporate_driver_id':corporate_driver_id,
    }
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_driver', data,this.httpOptions);
  }
  updateCorporateDriver(user:any){      
    let today =  user['dob'];
    user['dob']=this.datePipe.transform(today,"yyyy-MM-dd");   
    let today1 =  user['license_expiry'];
    user['license_expiry']=this.datePipe.transform(today1,"yyyy-MM-dd");  
    let _url=this._urlApi._getUrl();    
    return this._Http.post(_url+'update_corporate_driver', user,this.httpOptions);
  }  
  
  getRoutes(){
    let user_id=this._AuthenticationService.userId; 
    let users={};   
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_routes',users,this.httpOptions);
  }
  addRoute(routeD:any){
    let user_id=this._AuthenticationService.userId;    
    routeD['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'add_corporate_vendor_route',routeD,this.httpOptions);
  }
  getRoute(route_id){    
    let route={};   
    route['route_id']=route_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'corporate_vendor_route',route,this.httpOptions);
  }
  updateRoute(routeD:any){     
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'update_corporate_vendor_route',routeD,this.httpOptions);
  }
  getTripRouts(){
    let user_id=this._AuthenticationService.userId; 
    let users={};   
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_trip_routes',users,this.httpOptions);
  }
  addTrip(routeD:any){
    let user_id=this._AuthenticationService.userId;    
    routeD['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'add_corporate_trip',routeD,this.httpOptions);
  }
  
  getTrips(){
    let user_id=this._AuthenticationService.userId; 
    let users={};   
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_trips',users,this.httpOptions);
  }
  getTrip(trip_id){   
    let trip={};   
    trip['trip_id']=trip_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_trip',trip,this.httpOptions);
  }
  UpadateTrip(Trip:any){ 
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'update_corporate_trip',Trip,this.httpOptions);
  }
  getUnassignDriverVehicle(){
    let user_id=this._AuthenticationService.userId; 
    let users={};   
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl(); 
    return this._Http.post(_url+'unassign_corporate_vendor_vehicle_driver',users,this.httpOptions);
  }
  assignDriverVehicle(vehicleD:any){    
   let today =  vehicleD['last_assign_date'];
   vehicleD['last_assign_date']=this.datePipe.transform(today,"yyyy-MM-dd");  
    let user_id=this._AuthenticationService.userId;    
    vehicleD['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'assign_corporate_vendor_vehicle_driver',vehicleD,this.httpOptions);
  }
  getBookings(){
    let users={};
    let user_id=this._AuthenticationService.userId;    
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_vendor_booking',users,this.httpOptions);
  }
  getQuotations(){
    let users={};
    let user_id=this._AuthenticationService.userId;    
    users['user_id']=user_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'get_corporate_vendor_quotations',users,this.httpOptions);
  }
  getRoutesTrip(route_id){
    let users={};
    let user_id=this._AuthenticationService.userId;    
    users['user_id']=user_id;
    users['route_id']=route_id;    
    let _url=this._urlApi._getUrl();
    return this._Http.post(_url+'routes_trip',users,this.httpOptions);

  }
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-individual-vendor-vehicle-gallery',
  templateUrl: './individual-vendor-vehicle-gallery.component.html',
  styleUrls: ['./individual-vendor-vehicle-gallery.component.css']
})
export class IndividualVendorVehicleGalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/_services/data.service';
import { FormArray,FormControl,FormGroup,FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelect2Module } from 'ng-select2';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/user.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
import { Spinkit } from 'ng-http-loader';
import * as firebase from 'firebase/app';

declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  public spinkit = Spinkit;
  _searchForm :FormGroup;
  _searchListForm:FormGroup;
  _filterForm:FormGroup;
  _ratingForm:FormGroup
  _chatForm:FormGroup;
  _submitted = false;
  _LocationAData: Array<NgSelect2Module>;
  _LocationBData: Array<NgSelect2Module>;
  public exampleData: Array<NgSelect2Module>;
  __showControls = 'inbound';
  __showChat= 'off';
  public searchText : string;
  pickupLocation='';
  dropLocation='';
  startTime: Date;
  endTime: Date;
  selcteedTrip='';
  _vehicles:any;
  _vehicleTypes:any;
  _status:number;
  _msg:string;
  isMeridian = false;
  showSpinners = true;
  oneWayTrip=[];
  roundWayTrip=[];
  totalAmount:number;
  inbound_driver_trip_id:number=0;
  outbound_driver_trip_id:number=0;
  oneWayTripPrice=[];
  roundWayTripPrice=[];
  tripType:string;
  inboundPrice=0;
  outboundPrice=0;
  oneWayTrip_length:number=1;
  vehiclePath:string;
  _driverDetail:any;
  _diverEmail:string;
  _commentStatus:number;
 
  FireBaseReciverId:string;
  AllMessage:any;
  
  constructor(private _Router: Router,
    private _FormBuilder:FormBuilder,    
    private _DataService:DataService,
    public _AuthenticationService:AuthenticationService,  
    private _UserService:UserService,   
    public _UserValidation:UserValidationService,  
    ) {}
  ngOnInit() {
    this.exampleData = [
      {
        id: 'inbound',
        text: 'Inbound'
      },{
        id: 'outbound',
        //disabled: true,
        text: 'Outbound'
      },{
        id: 'round',
        text: 'Round'
      }      
    ];
    this._searchForm=this._FormBuilder.group({
      nature_trip:['inbound'],
      location_a:['',],
      leave_at:['',],
      location_b:['',], 
      return_at:['',],          
    });
    this._searchListForm = this._FormBuilder.group({
      inbound: ['', Validators.required],
      outbound: ['', Validators.required],
    });
    this._filterForm = this._FormBuilder.group({
      searchText:[''],     
    });
    this._chatForm = this._FormBuilder.group({
      chatText:['',Validators.required],     
    });
    
    this._ratingForm = this._FormBuilder.group({
      star: ['', Validators.required],
      comment: ['', Validators.required],     
       
    });
    this._DataService._getLocationPoints().subscribe(res=>{     
      var locationA = [];
      var locationB = [];
      for(var i=0; i<res['data']['loc_a'].length; i++) {
        var loc_a = res['data']['loc_a'][i].location_a ;       
        locationA.push(loc_a);
      }  
      for(var i=0; i<res['data']['loc_b'].length; i++) {
        var loc_b = res['data']['loc_b'][i].location_b ;      
        locationB.push(loc_b);
      }
      this._LocationAData  =  locationA;
      this._LocationBData  =  locationB;    
    });     
    this._DataService._getVehicle().subscribe(res=>{         
      this._vehicles=res['data']['vehicles'];
      this._vehicleTypes=res['data']['vehicle_types'];
    });      
    let s_data=this._DataService._getSearchData();    
    if(!s_data){
      this._Router.navigate(['individual']);  
      return;
    }   
    this.SearchTripDriver(s_data);    
  }    
  SearchTripDriver(search){      
    this._DataService._setSearchData(search);
    this.pickupLocation=search['location_a'];
    this.dropLocation=search['location_b'];
    this.startTime=search['leave_at'];    
    this.endTime=search['return_at'];   
    this.selcteedTrip=search['nature_trip'];  
    this.inboundPrice=0;
    this.outboundPrice=0; 
    this.inbound_driver_trip_id=0;
    this.outbound_driver_trip_id=0; 
    this.inboundShow=false;
    this.outboundShow=false;
    this._DataService._getSearchTripDriver(search).subscribe(res=>{       
      this.oneWayTrip=res['data']['one_way_trip'];
      this.roundWayTrip=res['data']['round_way_trip'];
      this.oneWayTrip_length =this.oneWayTrip.length; 
      this.vehiclePath=res['data']['vehicle_path'];
      //this.oneWayTripPrice=res['data']['trip_one_price'];
      //this.roundWayTripPrice=res['data']['trip_round_price'];   
      this.tripType=res['data']['trip_type'];    
      this.selcteedTrip=res['data']['trip_type'];
      /*if(res['data']['trip_one_price']['one_way_trip_price']){       
        let price=res['data']['trip_one_price']['one_way_trip_price'];
        this.inboundPrice=price;
        this.inbound_driver_trip_id=res['data']['trip_one_price']['driver_trip_id'];        
      }  
      if(res['data']['trip_round_price']['one_way_trip_price']){          
        let price=res['data']['trip_round_price']['one_way_trip_price'];
        this.outboundPrice=price;
        this.outbound_driver_trip_id=res['data']['trip_round_price']['driver_trip_id'];
      }        
      this.totalAmount=this.inboundPrice+this.outboundPrice;    */  
     });    
  }
  onSubmit(){  
	this.inbound_driver_user_id=0; // 15-9-2020
	this.outbound_driver_user_id=0; // 15-9-2020
    this._submitted = true;     
    if (this._searchForm.invalid) {
      return;
    }   
    if(!this._searchForm.value){
      this._Router.navigate(['individual']);  
      return;
    }       
    this.SearchTripDriver(this._searchForm.value); 
	this._searchListForm.controls['inbound'].reset(); // 15-9-2020
	this._searchListForm.controls['outbound'].reset(); // 15-9-2020
  }
  DriverDetails(driver_trip_id:number){    
    this._DataService.getTripDriverDetails(driver_trip_id).subscribe(res=>{      
      this._driverDetail=res['data'];  
      this._commentStatus=res['comment_status'];        
      this._diverEmail=res['data']['email'];    
      this.getFireBaseDriverID(this._diverEmail);      
    },error=>{
      console.log(error);
    });      
  }  
  starRating:number=0;
  commentsRating:string='';

  ratingSubmit() {
   
    let data={};
    data['booking_id']=0;
    data['trip_id']=this._driverDetail['trip_id'];
    data['driver_id']=this._driverDetail['driver_id'];
    data['driver_trip_id']=this._driverDetail['driver_trip_id'];
    data['rating']=this._ratingForm.value.star;
    data['comments']=this._ratingForm.value.comment;       
    this._UserService.setComments(data).subscribe(res=>{      
      if(res['status']==1){
        this.starRating=0;
        this.commentsRating='';
        $('#InputComment').find('.close').click();
        $('#viewProfile').find('.close').click();    
        let s_data=this._DataService._getSearchData();        
        this.SearchTripDriver(s_data);       
      }     
    },error => {        
      console.log(error);     
    }); 
  }
  calculateHours(startTime,endTime){    
    let startDate:any = new Date("January 1, 1970 " + startTime);
    let endDate:any = new Date("January 1, 1970 " + endTime);
    let  timeDiff = Math.abs(startDate - endDate);
    var hh:any = Math.floor(timeDiff / 1000 / 60 / 60);
    if(hh < 10) {
      hh = '0' + hh;
    }
    timeDiff -= hh * 1000 * 60 * 60;
    var mm:any = Math.floor(timeDiff / 1000 / 60);
    if(mm < 10) {
      mm = '0' + mm;
    }
    timeDiff -= mm * 1000 * 60;
    var ss:any = Math.floor(timeDiff / 1000);
    if(ss < 10) {
      ss = '0' + ss;
    }   
    return hh;
  }
  calculateMinutes(startTime,endTime){    
    let startDate:any = new Date("January 1, 1970 " + startTime);
    let endDate:any = new Date("January 1, 1970 " + endTime);
    let  timeDiff = Math.abs(startDate - endDate);
    var hh:any = Math.floor(timeDiff / 1000 / 60 / 60);
    if(hh < 10) {
      hh = '0' + hh;
    }
    timeDiff -= hh * 1000 * 60 * 60;
    var mm:any = Math.floor(timeDiff / 1000 / 60);
    if(mm < 10) {
      mm = '0' + mm;
    }
    timeDiff -= mm * 1000 * 60;
    var ss:any = Math.floor(timeDiff / 1000);
    if(ss < 10) {
      ss = '0' + ss;
    }    
    return mm;
  }
  
 
  chnageDriverTrip(driver_trip_id:number,trip_type:string){    
    this._DataService.chnageDriverTrip(driver_trip_id).subscribe(res=>{     
      if(trip_type=='round'){
        this.roundWayTripPrice=res['data']['trip_one_price']; 
        this.outboundPrice=res['data']['trip_one_price']['one_way_trip_price'];
        this.totalAmount=this.inboundPrice+this.outboundPrice;   
        this.outbound_driver_trip_id=res['data']['trip_one_price']['driver_trip_id'];   
      }else{
        this.oneWayTripPrice=res['data']['trip_one_price']; 
        this.inboundPrice=res['data']['trip_one_price']['one_way_trip_price'];
        this.totalAmount=this.inboundPrice+this.outboundPrice;
        this.inbound_driver_trip_id=res['data']['trip_one_price']['driver_trip_id'];      
      }     
    },error=>{
      console.log(error);
    });      
  }
  showControls(showControls) {    
    this.__showControls = showControls;
  }
  onSetDriverId(){   
    let driverId={
      'inbound_driver_trip_id':this.inbound_driver_trip_id,
      'outbound_driver_trip_id':this.outbound_driver_trip_id,
    }  
    
    if(!driverId){
      this._Router.navigate(['individual']);  
      return;
    }   
    this._DataService.setDriveId(driverId);
    this._Router.navigate(['search-details']);    
  }
  saveFavoritesRoute(){    
    let data={      
      'user_id':this._AuthenticationService.userId,
      'start_point':this.pickupLocation,
      'end_point':this.dropLocation,
      'start_time':this.startTime,
      'end_time':this.endTime,
      'nature_trip':this.selcteedTrip,
      'user_type':this._AuthenticationService.isRole,      
    }
    this._DataService.saveFavoritesRoute(data).subscribe(res=>{         
        this._status=res['status'];
        this._msg=res['message'];   
      },error=>{
        console.log(error);
    });     
  }
  _comments:any;
  ViewComments(trip_id,driver_id){   
    this._DataService.getComments(trip_id,driver_id).subscribe(res=>{         
      this._comments=res['data'];      
    },error=>{
      console.log(error);
    });    
  }
  openChat(show){ 
   
    var x=firebase.database().ref().child("Chats").on("value",snapshot => {
      if (snapshot.exists()){
        const userData = snapshot.val();      
        var resultArray = Object.keys(userData).map(function(personNamedIndex){
          let person = userData[personNamedIndex];
          // do something with person
          return person;
      });          
        this.setMessages(resultArray);
      }      
    });  
    this.__showChat = show;
    
  }
  chatText:string;
  chatTextTest:string;
  doCharting(){
    if(this.chatTextTest!=''){
      let u_id=this._AuthenticationService.FireBaseUID;// Fisrt Child Login User    
      let chatData = {  
          isseen: true,   
          message: this._chatForm.value.chatText,
          receiver: this.FireBaseReciverId,//'oMNtqwbeQ1XfZQpJ2mZVjlYWOtq1', // vr// Dr  
          sender: u_id,//'5Wn5Lpr41BeRuBAQV5xiiniKyOl2', // Uddin   
      };
      firebase.database().ref().child('/Chats').push(chatData); 
      let r_id=this.FireBaseReciverId; // Reciver sencond  Child (Driver/User) 
      var ref = firebase.database().ref('Chatlist').child(u_id).child(r_id);ref.once("value").then(function(snapshot){
        var a = snapshot.exists();
          let cldata={id:u_id,};     
          if(!a){
            ref.child("id").set(cldata);     
            ref.set(cldata);
          }    
        }); 
      var ref1 = firebase.database().ref('Chatlist').child(r_id).child(u_id);  ref1.once("value").then(function(snapshot){
        var a = snapshot.exists();
        let cldata={id:u_id,};
        if(!a){    
          ref1.child("id").set(cldata);      
          ref1.set(cldata);
        }   
      });     
      this.chatTextTest='';
    }
  }
  getFireBaseDriverID(_diverEmail) { 
    var x=firebase.database().ref().child("Users").
        orderByChild("email").equalTo(_diverEmail).once("value",snapshot => {
        if (snapshot.exists()){
          const userData = snapshot.val();          
          var resultArray = Object.keys(userData).map(function(personNamedIndex){
            let person = userData[personNamedIndex];
            // do something with person
            return person;
        });          
          this.SetFireBaseReciverId(resultArray[0]['id']);
        }      
    });  
  }
  SetFireBaseReciverId(x){      
    this.FireBaseReciverId=x;   
  }
  AllMessageLength:number;
  setMessages(x){   
    this.AllMessage=x;       
    this.AllMessageLength=x.length;

  //  this.scrollDown();
    
  }
  convertInt(x){
    var x1=parseInt(x);
    return x1;
  }

  inboundShow:boolean=false;
  outboundShow:boolean=false;  
  
  inbound_driver_user_id:number=0;
  outbound_driver_user_id:number=0;
  
  outbound_status:boolean=false; 
  chnageOneWayDriverTrip(data:any){   
    this.inboundShow=true;    
    this.oneWayTripPrice=data;
    this.inboundPrice=data['one_way_trip_price'];    
    this.totalAmount=this.inboundPrice+this.outboundPrice;
    this.inbound_driver_trip_id=data['driver_trip_id'];  

	// 14-9-2020
	if(this.oneWayTrip.length>0 && this.roundWayTrip.length>0 && this.outbound_status==false){
		//this._searchListForm.controls['outbound'].reset();
		let obj = this.roundWayTrip.find(o => o.user_id === data['user_id']);
		if (obj !== undefined){
			this.outbound_driver_user_id=data['user_id'];
			this.fidDriverTripOut(obj);
		}else{
			this.outboundShow=false; 
			this.roundWayTripPrice=[];
			this.totalAmount=this.totalAmount-this.outboundPrice;
			this.outbound_driver_user_id=0; 
			//this._searchListForm.controls['outbound'].reset();
		}
	}
}
  chnageRoundWayDriverTrip(data:any){  
    this.outboundShow=true; 
    this.roundWayTripPrice=data;
    this.outboundPrice=data['one_way_trip_price'];   
    this.totalAmount=this.inboundPrice+this.outboundPrice;
    this.outbound_driver_trip_id=data['driver_trip_id']; 
	this.outbound_status=true;		
  } 
  fidDriverTripIn(data:any){
	this.inboundShow=true;    
    this.oneWayTripPrice=data;
    this.inboundPrice=data['one_way_trip_price'];    
    this.totalAmount=this.inboundPrice+this.outboundPrice;
    this.inbound_driver_trip_id=data['driver_trip_id'];
	//this.outbound_driver_trip_id=data['driver_trip_id'];
  }
  fidDriverTripOut(data:any){
	this.outboundShow=true; 
    this.roundWayTripPrice=data;
    this.outboundPrice=data['one_way_trip_price'];   
    this.totalAmount=this.inboundPrice+this.outboundPrice;
    this.outbound_driver_trip_id=data['driver_trip_id']; 
  }
  // scrollDown(){
  //   $(".panel-body").animate({
  //     scrollTop: $('.panel-body').get(0).scrollHeight
  //   }, 500);   
  // }

}
import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  _forgetPasswordForm :FormGroup;
  _submitted = false;
  emailNotEmpty:string;
  _status:number;  
  _msg:string;
  public spinkit = Spinkit;
  constructor(
    private router: Router,
    private _FormBuilder:FormBuilder,
    private _DataService:DataService,
  ) { }

  ngOnInit() {
    this._forgetPasswordForm=this._FormBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  } 
 
  get f() { return this._forgetPasswordForm.controls; }
  onSubmit() {
    this._submitted = true;
    if (this._forgetPasswordForm.invalid) {
      return;
    } 

    this._DataService.forgotPassword(this._forgetPasswordForm.value).subscribe(
      res => {         
        this._status=res['status'];
        this._msg=res['message'];
       
      },
      error => {        
        console.log(error);
        this._status=0;
        this._msg='Unable to Forgot Password. Because of Internal Server Error,Try again Later';            
      });
  }

}

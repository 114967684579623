import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_auth/auth.guard';
import { RoleGuard } from './_auth/role.guard';

import { SiteLayoutComponent } from './component/_layout/site-layout/site-layout.component';
import { LandingComponent } from './component/landing/landing.component';
import { IndividualUserComponent } from './component/individual-user/individual-user.component';
import { SearchComponent } from './component/search/search.component';
import { SearchDetailsComponent } from './component/search-details/search-details.component';
import { VendorComponent } from './component/vendor/vendor.component';
import { CorporateComponent } from './component/corporate/corporate.component';
import { AboutComponent } from './component/about/about.component';
import { VServiceComponent } from './component/v-service/v-service.component';
import { AgentComponent } from './component/agent/agent.component';
import { FaqComponent } from './component/faq/faq.component';
import { RentFeetComponent } from './component/rent-feet/rent-feet.component';
import { RegisterComponent } from './component/register/register.component';
import { LoginComponent } from './component/login/login.component';
import { ForgetPasswordComponent } from './component/forget-password/forget-password.component';
import { CancellationComponent } from './component/cancellation/cancellation.component';
import { TermsComponent } from './component/terms/terms.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './component/contact-us/contact-us.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ProfileComponent } from './component/profile/profile.component';
import { IndividualVendorDashboardComponent } from './component/individual-vendor-dashboard/individual-vendor-dashboard.component';
import { CorporateVendorDashboardComponent } from './component/corporate-vendor-dashboard/corporate-vendor-dashboard.component';
import { CorporateUserDashboardComponent } from './component/corporate-user-dashboard/corporate-user-dashboard.component';
import { ChangePasswordComponent } from './component/change-password/change-password.component';
import { IndividualVendorProfileComponent } from './component/individual-vendor-profile/individual-vendor-profile.component';
import { IndividualVendorVehicleComponent } from './component/individual-vendor-vehicle/individual-vendor-vehicle.component';
import { IndividualVendorVehicleProfileComponent } from './component/individual-vendor-vehicle-profile/individual-vendor-vehicle-profile.component';
import { IndividualVendorVehicleGalleryComponent } from './component/individual-vendor-vehicle-gallery/individual-vendor-vehicle-gallery.component';
import { IndividualVendorRoutesComponent } from './component/individual-vendor-routes/individual-vendor-routes.component';
import { IndividualVendorSingleRouteComponent } from './component/individual-vendor-single-route/individual-vendor-single-route.component';
import { IndividualVendorCreateTripComponent } from './component/individual-vendor-create-trip/individual-vendor-create-trip.component';
import { CorporateVendorProfileComponent } from './component/corporate-vendor-profile/corporate-vendor-profile.component';
import { CorporateVendorVehiclesComponent } from './component/corporate-vendor-vehicles/corporate-vendor-vehicles.component';
import { CorporateVendorVehicleComponent } from './component/corporate-vendor-vehicle/corporate-vendor-vehicle.component';
import { CorporateVendorDriversComponent } from './component/corporate-vendor-drivers/corporate-vendor-drivers.component';
import { CorporateVendorDriverComponent } from './component/corporate-vendor-driver/corporate-vendor-driver.component';
import { CorporateVendorAddVehicleComponent } from './component/corporate-vendor-add-vehicle/corporate-vendor-add-vehicle.component';
import { CorporateVendorEditVehicleComponent } from './component/corporate-vendor-edit-vehicle/corporate-vendor-edit-vehicle.component';
import { CorporateVendorAddDriverComponent } from './component/corporate-vendor-add-driver/corporate-vendor-add-driver.component';
import { CorporateVendorEditDriverComponent } from './component/corporate-vendor-edit-driver/corporate-vendor-edit-driver.component';
import { CorporateUserProfileComponent } from './component/corporate-user-profile/corporate-user-profile.component';
import { CorporateUserRequestQuoteComponent } from './component/corporate-user-request-quote/corporate-user-request-quote.component';
import { FavouriteRouteComponent } from './component/favourite-route/favourite-route.component';
import { CorporateVendorAddRouteComponent } from './component/corporate-vendor-add-route/corporate-vendor-add-route.component';
import { CorporateVendorEditRouteComponent } from './component/corporate-vendor-edit-route/corporate-vendor-edit-route.component';
import { CorporateVendorRoutesComponent } from './component/corporate-vendor-routes/corporate-vendor-routes.component';

import { CorporateVendorAddTripComponent } from './component/corporate-vendor-add-trip/corporate-vendor-add-trip.component';
import { CorporateVendorEditTripComponent } from './component/corporate-vendor-edit-trip/corporate-vendor-edit-trip.component';
import { CorporateVendorTripsComponent } from './component/corporate-vendor-trips/corporate-vendor-trips.component';
import { CorporateVendorAssignDriverVehiclesComponent } from './component/corporate-vendor-assign-driver-vehicles/corporate-vendor-assign-driver-vehicles.component';
import { CorporateVendorAssignTripDriverComponent } from './component/corporate-vendor-assign-trip-driver/corporate-vendor-assign-trip-driver.component';
import { CorporateUserRequestQuoteDetailsComponent } from './component/corporate-user-request-quote-details/corporate-user-request-quote-details.component';
import { CorporateVendorBookingsComponent } from './component/corporate-vendor-bookings/corporate-vendor-bookings.component';
import { CorporateVendorQuotesComponent } from './component/corporate-vendor-quotes/corporate-vendor-quotes.component';
import { CorporateVendorRouteTripsComponent } from './component/corporate-vendor-route-trips/corporate-vendor-route-trips.component';
import { CorporateVendorCreateRouteTripComponent } from './component/corporate-vendor-create-route-trip/corporate-vendor-create-route-trip.component';  
import { CorporateVendorEditRouteTripComponent } from './component/corporate-vendor-edit-route-trip/corporate-vendor-edit-route-trip.component';


import { IndividualUserBookingsComponent } from './component/individual-user-bookings/individual-user-bookings.component';

import { EmailVerifyComponent } from './component/email-verify/email-verify.component';
const routes: Routes = [  
  { path:'',component:LandingComponent},  
  { 
    path: 'user', 
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path:'',component:DashboardComponent,pathMatch: 'full',canActivate: [RoleGuard],data: {role: 'C'}},
      {path:'edit-profile',component:ProfileComponent,canActivate: [RoleGuard],data: {role: 'C'}},   
      {path:'favourite-route',component:FavouriteRouteComponent,canActivate: [RoleGuard],data: {role: 'C'}},       
      {path:'change-password',component:ChangePasswordComponent,canActivate: [RoleGuard],data: {role: 'C'}}, 
      {path:'bookings',component:IndividualUserBookingsComponent,canActivate: [RoleGuard],data: {role: 'C'}},      
    ]
  },
  { 
    path: 'indv-vendor', 
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path:'',component:IndividualVendorDashboardComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'edit-profile',component:IndividualVendorProfileComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'vehicle',component:IndividualVendorVehicleComponent,canActivate: [RoleGuard],data: {role: 'D'}}, 
      {path:'add-vehicle',component:IndividualVendorVehicleProfileComponent,canActivate: [RoleGuard],data: {role: 'D'}},
     // {path:'edit-gallery',component:IndividualVendorVehicleGalleryComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'routes',component:IndividualVendorRoutesComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'route/:id',component:IndividualVendorSingleRouteComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'add-trip',component:IndividualVendorCreateTripComponent,canActivate: [RoleGuard],data: {role: 'D'}},
      {path:'change-password',component:ChangePasswordComponent,canActivate: [RoleGuard],data: {role: 'D'}},    
    ]
  },
  { 
    path: 'corp-vendor',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [    
      {path:'',component:CorporateVendorDashboardComponent,canActivate: [RoleGuard],data: {role: 'CV'}},        
      {path:'change-password',component:ChangePasswordComponent,canActivate: [RoleGuard],data: {role: 'CV'}},     
      {path:'edit-profile',component:CorporateVendorProfileComponent,canActivate: [RoleGuard],data: {role: 'CV'}}, 
      {path:'vehicles',component:CorporateVendorVehiclesComponent,canActivate: [RoleGuard],data: {role: 'CV'}}, 
      {path:'vehicle/:id',component:CorporateVendorVehicleComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'add-vehicle',component:CorporateVendorAddVehicleComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'edit-vehicle/:id',component:CorporateVendorEditVehicleComponent,canActivate: [RoleGuard],data: {role: 'CV'}},        
      {path:'drivers',component:CorporateVendorDriversComponent,canActivate: [RoleGuard],data: {role: 'CV'}}, 
      {path:'driver/:id',component:CorporateVendorDriverComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'add-driver',component:CorporateVendorAddDriverComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'edit-driver/:id',component:CorporateVendorEditDriverComponent,canActivate: [RoleGuard],data: {role: 'CV'}}, 
      {path:'add-route',component:CorporateVendorAddRouteComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'edit-route/:id',component:CorporateVendorEditRouteComponent,canActivate: [RoleGuard],data: {role: 'CV'}},      
      {path:'routes',component:CorporateVendorRoutesComponent,canActivate: [RoleGuard],data: {role: 'CV'}},   
      {path:'add-trip',component:CorporateVendorAddTripComponent,canActivate: [RoleGuard],data: {role: 'CV'}},   
      {path:'edit-trip/:id',component:CorporateVendorEditTripComponent,canActivate: [RoleGuard],data: {role: 'CV'}},   
      {path:'trips',component:CorporateVendorTripsComponent,canActivate: [RoleGuard],data: {role: 'CV'}},  
      {path:'assign-driver-vehicle',component:CorporateVendorAssignDriverVehiclesComponent,canActivate: [RoleGuard],data: {role: 'CV'}},  
      {path:'assign-trip-driver',component:CorporateVendorAssignTripDriverComponent,canActivate: [RoleGuard],data: {role: 'CV'}},  
      {path:'bookings',component:CorporateVendorBookingsComponent,canActivate: [RoleGuard],data: {role: 'CV'}},  
      {path:'quotations',component:CorporateVendorQuotesComponent,canActivate: [RoleGuard],data: {role: 'CV'}}, 
      {path:'route-trips/:id',component:CorporateVendorRouteTripsComponent,canActivate: [RoleGuard],data: {role: 'CV'}},   
      {path:'add-route-trip/:id',component:CorporateVendorCreateRouteTripComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
      {path:'edit-route-trip/:id',component:CorporateVendorEditRouteTripComponent,canActivate: [RoleGuard],data: {role: 'CV'}},
    ]
  },
  { 
    path: 'corp-user',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [    
      {path:'',component:CorporateUserDashboardComponent,canActivate: [RoleGuard],data: {role: 'CU'}},
      {path:'edit-profile',component:CorporateUserProfileComponent,canActivate: [RoleGuard],data: {role: 'CU'}},
      {path:'request-quote',component:CorporateUserRequestQuoteComponent,canActivate: [RoleGuard],data: {role: 'CU'}},
      {path:'change-password',component:ChangePasswordComponent,canActivate: [RoleGuard],data: {role: 'CU'}},     
      {path:'request-quote-details/:id',component:CorporateUserRequestQuoteDetailsComponent,canActivate: [RoleGuard],data: {role: 'CU'}},
    ]
  }, 
  { 
    path: '', 
    component: SiteLayoutComponent,
    children: [     
      {path:'individual',component:IndividualUserComponent},
      {path:'search',component:SearchComponent},
      {path:'search-details',component:SearchDetailsComponent},
      {path:'vendor',component:VendorComponent},
      {path:'corporate',component:CorporateComponent},
      {path:'about',component:AboutComponent},
      {path:'services',component:VServiceComponent},
      {path:'agent',component:AgentComponent},
      {path:'faq',component:FaqComponent},
      {path:'rent-a-fleet',component:RentFeetComponent,},
      {path:'register',component:RegisterComponent},
      {path:'login',component:LoginComponent},
      {path:'forget-password',component:ForgetPasswordComponent},
      {path:'cancellation',component:CancellationComponent},
      {path:'terms',component:TermsComponent},
      {path:'privacy-policy',component:PrivacyPolicyComponent},
      {path:'contact-us',component:ContactUsComponent},
      {path:'page-not-found',component:PageNotFoundComponent},
      {path:'verify_email/:email_code', component: EmailVerifyComponent }
    ]
  },   
  { path: '**', redirectTo: '' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true ,scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

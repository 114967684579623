import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';
import { DriverTripService } from 'src/app/_services/driver-trip.service';
import { UserValidationService } from 'src/app/_services/user-validation.service';
@Component({
  selector: 'app-corporate-vendor-dashboard',
  templateUrl: './corporate-vendor-dashboard.component.html',
  styleUrls: ['./corporate-vendor-dashboard.component.css']
})
export class CorporateVendorDashboardComponent implements OnInit {
  public spinkit = Spinkit;
  _corporatevendorData:any;
  _corporatevehicleData:any;
  _corporatedriverData:any;
  routeData:any;
  routeLength:number;
  image_path: string; 
  vehicle_path: string; 
  _createTripForm :FormGroup;
  _Routes:any;
  _RoutesLength:number;
  _corporatedriverLength:number;
  _corporatevehicleLength:number;
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _DriverTripService:DriverTripService,
    public _UserValidation:UserValidationService,
  ){ }

  ngOnInit() {
    this._createTripForm=this._formBuilder.group({
      route_id:[''],      
    });  
    this._CorporateVendorService.getCorporateVendorProfile().subscribe(dat=>{      
      this._corporatevendorData=dat['data']['corporate_vendor'];
      this._corporatevehicleData=dat['data']['corporate_vehicle'];
      this._corporatevehicleLength=dat['data']['corporate_vehicle'].length;
      this._corporatedriverData=dat['data']['corporate_driver'];   
      this._corporatedriverLength=dat['data']['corporate_driver'].length;   
      this._Routes=dat['data']['corporate_routes'];
      this._RoutesLength=dat['data']['corporate_routes'].length;
      this.vehicle_path=dat['data']['vehicle_path'];
      this.image_path=dat['data']['image_path'];   
    },
    error => {        
      console.log(error);     
    });    
    this._CorporateVendorService.getTripRouts().subscribe(res=>{
      this.routeData=res['data'];
      this.routeLength=res['data'].length;
    },error => {        
      console.log(error);     
    });

  }
  showTripDiv:boolean=false;
  area_route:any;    
  trips:any;
  routeChanged(evt){   
    this.showTripDiv=true; 
    let route_id=evt.target.value;        
    this._DriverTripService.getAssignCorporateTrip(route_id).subscribe(res=>{     
      this.area_route=res['data']['area_route'];     
      this.trips=res['data']['trips'];       
    });  
  } 
  onSubmit(){}
}
import { Component, OnInit } from '@angular/core';
import {  FormArray,FormControl,FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-create-route-trip',
  templateUrl: './corporate-vendor-create-route-trip.component.html',
  styleUrls: ['./corporate-vendor-create-route-trip.component.css']
})
export class CorporateVendorCreateRouteTripComponent implements OnInit {
  public spinkit = Spinkit;
  _editVehicleProfileForm :FormGroup;
  _submitted1 = false;
  _Route:any;//=[];
  _status1:number;
  _msg1:string;
  days_trip=[
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thus' },
    { id: 6, name: 'Fri' },
    { id: 7, name: 'Sat' },
  ];
  constructor(
    private _formBuilder: FormBuilder,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this._editVehicleProfileForm = this._formBuilder.group({
      route_id: ['', Validators.required],
      start_point: ['', Validators.required],
      end_point: ['', Validators.required],
      start_time: ['00:00:00', Validators.required],
      end_time: ['00:00:00', Validators.required],
      nature_trip: ['inbound', Validators.required], 
      days_trip: new FormArray([
        new FormControl('Sun'),
        new FormControl('Mon'),
        new FormControl('Tues'),
        new FormControl('Wed'),
        new FormControl('Thus'),
        new FormControl(''),
        new FormControl(''),        
      ]),         
    });
    const route_id= this._ActivatedRoute.snapshot.params.id;    
    this.getRoutesTrip(route_id);     
  }
  s_point:string='';
  e_point:string='';
  v_locations:string='';
  a_route_id:number;
  inoutTime:string='inbound';
  getLocation(evt){
    let selectedIndex:number = evt.target["selectedIndex"];
    let location_a=evt.target.options[selectedIndex].getAttribute("data-location_a");
    let location_b=evt.target.options[selectedIndex].getAttribute("data-location_b");
    let via_locations=evt.target.options[selectedIndex].getAttribute("data-via_locations");
    this.s_point=location_a;
    this.e_point=location_b;
    this.v_locations=via_locations;    
  }
  changeLocation(){   
    let a_point=this.s_point;
    let b_point=this.e_point;
    this.s_point=b_point;     
    this.e_point=a_point;
    let Nature=this._editVehicleProfileForm.value;
    this.inoutTime=Nature.nature_trip;    
  }
  get fTrip() { return this._editVehicleProfileForm.controls; }
  onTripSubmit(){
    this._submitted1 = true;      
     if(this._editVehicleProfileForm.invalid){
       return false;
     }    
    const selectedrentDays = this._editVehicleProfileForm.value.days_trip
    .map((v, i) => v ? this.days_trip[i].name : ' ')
    .filter(v =>v);  
    this._editVehicleProfileForm.value.days_trip=selectedrentDays;
     this._CorporateVendorService.addTrip(this._editVehicleProfileForm.value).subscribe(res=>{     
       this._status1=res['status'];  
       this._msg1=res['message'];         
     },error => {        
       console.log(error);  
       this._status1=0;
       this._msg1='Unable to add Driver. Because of Internal Server Error,Try again Later';
     }); 
   }
   getRoutesTrip(route_id){    
    this._CorporateVendorService.getRoutesTrip(route_id).subscribe(res=>{     
      this._Route=res['data']['route'];    
      this.a_route_id=res['data']['route']['route_id']
      this.s_point=res['data']['route']['location_a'];
      this.e_point=res['data']['route']['location_b'];
      this.v_locations=res['data']['route']['via_locations'];      
    },error => {        
      console.log(error);     
    });
  }
}
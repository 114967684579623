import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { DriverService } from 'src/app/_services/driver.service';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { DataService } from 'src/app/_services/data.service';
@Component({
  selector: 'app-individual-vendor-vehicle-profile',
  templateUrl: './individual-vendor-vehicle-profile.component.html',
  styleUrls: ['./individual-vendor-vehicle-profile.component.css']
})
export class IndividualVendorVehicleProfileComponent implements OnInit {
  public spinkit = Spinkit;
  _editVehicleProfileForm :FormGroup;
  _submitted = false;
  _VehicleTypes=[];
  _status:number;
  _msg:string;
  constructor(
    private _formBuilder: FormBuilder,
    private _DriverService:DriverService,
    private _DataService:DataService,
    private _Router:Router,
  ) { }
  ngOnInit() {
    this._editVehicleProfileForm = this._formBuilder.group({
      vehicle_type_id: ['', Validators.required],
      vehicle_make: ['', Validators.required],
      vehicle_model: ['', Validators.required],
      vehicle_seating_capacity: ['', Validators.required],
      ownership_type: ['own', Validators.required],
      service_type: ['regular', Validators.required],
      traffic_plate_number: ['', Validators.required],
      dafter_details: ['', Validators.required],
      vender_id: ['1', Validators.required],
    });
    
    this._DataService.getVehicleType().subscribe(res=>{
      this._VehicleTypes=res['data'];
    },error => {        
      console.log(error);     
    });
  }
  get f() { return this._editVehicleProfileForm.controls; }
  onSubmit(){
    this._submitted = true;      
    if(this._editVehicleProfileForm.invalid){
      return false;
    }    
    this._DriverService.VehicleAssign(this._editVehicleProfileForm.value).subscribe(res=>{     
      this._status=res['status'];      
      if(this._status==0){
        this._Router.navigate(['/indv-vendor/add-vehicle']);  
        this._msg=res['message'];    
      }  else if(this._status==1){
        this._Router.navigate(['/indv-vendor/vehicle']);  
      } 
    },error => {        
      console.log(error);     
    });
  }
}
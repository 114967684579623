import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { Spinkit } from 'ng-http-loader';
import { UserValidationService } from 'src/app/_services/user-validation.service';
export interface userData {
  name: string;
  phone_number:string;
  civil_id: string;
  email: string;
  gender: string;
  dob: string;
  working_shift_timings: string;
  whatsapp_number: string;
  imo_number: string;
  paci_no: string;
  home_address: string;
  emergency_contact: string;
  office_address: string;
  reach_work: string;
  leave_work: string;
  trip:string;
  want_agent:string;
}
export interface userDaysData {  
  sun: string;
  mon: string;
  tues: string;
  wed: string;
  thur: string;
  fri: string;
  sat: string;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit { 
  public spinkit = Spinkit;
  _userData: userData;// = {};
  _userDaysData:userDaysData;//={};
  image_path: string;  
  profile_image: string;  
  constructor(    
    private _UserService:UserService,
    public _UserValidation:UserValidationService,
    ) { }
  ngOnInit() { 
    this._UserService.getUserProfile().subscribe(dat=>{      
      this._userData=dat['data'];
      this._userDaysData=dat['data']['working_days'];         
    },
    error => {        
      console.log(error);     
    });
    
    this._UserService.getUserAvatar().subscribe(res=>{
      if(res['data']['profile_image']){
        this.profile_image=res['data']['profile_image'];
        this.image_path=res['data']['image_path'];
      }     
    },error => {        
      console.log(error);     
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup , FormBuilder,Validators} from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgSelect2Module } from 'ng-select2';
import { DataService } from 'src/app/_services/data.service';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService } from 'src/app/_services/authentication.service';
@Component({
  selector: 'app-individual-user',
  templateUrl: './individual-user.component.html',
  styleUrls: ['./individual-user.component.css']
})
export class IndividualUserComponent implements OnInit {
  public spinkit = Spinkit;
  _searchForm :FormGroup;
  _submitted = false;
  __showControls = 'inbound123';
  _LocationAData: Array<NgSelect2Module>;
  _LocationBData: Array<NgSelect2Module>; 
  _Governorates:any;
  _VehiclesTypes:any;
  _VehiclesTypesPath:string;
  constructor(
    private _Router: Router,
    private _FormBuilder:FormBuilder,    
    private _DataService :DataService,   
    public _AuthenticationService:AuthenticationService,
  ) { }
  isMeridian = false;
  showSpinners = true;  
  customSilders: OwlOptions = {
    loop:true, dots: false, nav:false, navText:['', ''], items:1, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: {0:{items: 1}, 400:{items: 1}, 740:{items: 1}, 940:{items: 1} }
  }
  customCities: OwlOptions = {    
    loop:true, dots: false, nav:false, items:5, margin:30, autoplay:true, autoplayHoverPause:true, smartSpeed:1000,  
    responsive: { 0:{items:2}, 768:{items:4}, 992:{items:5}, 1199:{items:5} }
  }
  ngOnInit() {  
    
    this._searchForm=this._FormBuilder.group({
      nature_trip:[''],
      location_a:['',Validators.required],
      leave_at:[''],
      location_b:['',Validators.required], 
      return_at:[''],
    });    
    this._DataService._getLocationPoints().subscribe(res=>{     
      var locationA = [];
      var locationB = [];
      for(var i=0; i<res['data']['loc_a'].length; i++) {
         var loc_a = res['data']['loc_a'][i].location_a ;       
         locationA.push(loc_a);
      }  
      for(var i=0; i<res['data']['loc_b'].length; i++) {
        var loc_b = res['data']['loc_b'][i].location_b ;      
        locationB.push(loc_b);
      }
      this._LocationAData  =  locationA;
      this._LocationBData  =  locationB;   
    });

    this._DataService._getGovernorates().subscribe(res=>{
      this._Governorates=res['data'];
    });  
    this._DataService._getVehiclesTypes().subscribe(res=>{
      this._VehiclesTypes=res['data'];  
      this._VehiclesTypesPath=res['vehicle_type_path'];
       
    });   
  }
  showControls(showControls) {    
    this.__showControls = showControls;
  }
   
   get f() { return this._searchForm.controls; }
  onSubmit(){     
    this._submitted = true;    
    if (this._searchForm.invalid) {
      return;
    } 
    this._DataService._setSearchData(this._searchForm.value);
    this._Router.navigate(['search']);      
  }
  Governorate:any;
  GovernorateCities:any;
  GovernorateCitieRoutes:any;
  GovernorateCitieRoutesLength:number;
  getGovernorateCity(governorate_id){    
    this._DataService._getGovernorateCities(governorate_id).subscribe(res=>{     
      this.Governorate=res['data']['Governorate'];
      this.GovernorateCities=res['data']['GovernorateCity'];      
    });    
  } 
  getGovernorateCityName(governorate_city){       
    this._DataService.getGovernorateCityName(governorate_city).subscribe(res=>{       
      this.GovernorateCitieRoutes=res['data'];
      this.GovernorateCitieRoutesLength=res['data'].length;
    });   
  }  
  SearchGovernorateCitie(location_a:string,location_b:string){
    let sdata={
      'nature_trip':'round',
      'leave_at':'',
      'return_at':'',
    };
    sdata['location_a']=location_a;
    sdata['location_b']=location_b;        
    this._DataService._setSearchData(sdata);   
    this._Router.navigate(['search']);
  }
  wantAgent(type,agent){
    this._DataService.setUserTypeRole(type);   
    this._DataService.setUsergent(agent);   
    this._Router.navigate(['/register']);   
  }
  _status:number;
  _msg:string;
  isShown:boolean=true;
  BecomeAsAgent(agent){
    this._DataService._BecomeAsAgent(agent).subscribe(res=>{  
     
      this._status=res['status'];
      this._msg=res['message'];  
      if(this._status==1){
        this.isShown=false;
      }        
    });   
  }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements  CanActivate{
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,    
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isRole = this._authenticationService.isRole;
    if (isRole===route.data['role']) {
       
        return true;
    }
    
    this._router.navigate(['/page-not-found']);
    return false;
}
}


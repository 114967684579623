import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { DataService } from 'src/app/_services/data.service';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
@Component({
  selector: 'app-corporate-vendor-edit-route',
  templateUrl: './corporate-vendor-edit-route.component.html',
  styleUrls: ['./corporate-vendor-edit-route.component.css']
})
export class CorporateVendorEditRouteComponent implements OnInit {

  public spinkit = Spinkit;
  _editVehicleProfileForm :FormGroup;
  _submitted = false;
  _DestinationType=[];
  _status:number;
  _msg:string;
  _Route={
    route_id:'',
    destination_type_id:'',
    location_a:'',
    location_b:'',
    via_locations:'',    
  }
  constructor(
    private _formBuilder: FormBuilder,
    private _DataService:DataService,
    private _CorporateVendorService:CorporateVendorService,
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
  ) { }

  ngOnInit() {
    this._editVehicleProfileForm = this._formBuilder.group({
      destination_type_id: ['', Validators.required],
      location_a: ['', Validators.required],
      location_b: ['', Validators.required],
      via_locations: ['', Validators.required], 
      route_id:['', Validators.required],     
    });
    this._DataService.getDestinationTypes().subscribe(res=>{
      this._DestinationType=res['data'];
    },error => {        
      console.log(error);     
    });   
    const route_id= this._ActivatedRoute.snapshot.params.id;  
    this._CorporateVendorService.getRoute(route_id).subscribe(res=>{
      this._Route=res['data'];
    },error => {        
      console.log(error);     
    });
    
  }
  get f() { return this._editVehicleProfileForm.controls; }
  onSubmit(){
    this._submitted = true;      
     if(this._editVehicleProfileForm.invalid){
       return false;
     }      
     this._CorporateVendorService.updateRoute(this._editVehicleProfileForm.value).subscribe(res=>{     
       this._status=res['status'];  
       this._msg=res['message'];      
       if(this._status==1){
         this._Router.navigate(['/corp-vendor/routes']);  
       } 
     },error => {        
       console.log(error);  
       this._status=0;
       this._msg='Unable to add Driver. Because of Internal Server Error,Try again Later';
     }); 
   }

}

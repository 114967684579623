import { Component, OnInit } from '@angular/core';
import { CorporateVendorService } from 'src/app/_services/corporate-vendor.service';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-corporate-vendor-drivers',
  templateUrl: './corporate-vendor-drivers.component.html',
  styleUrls: ['./corporate-vendor-drivers.component.css']
})
export class CorporateVendorDriversComponent implements OnInit {
  public spinkit = Spinkit;
  _corporatedriverData:any;
  image_path:string;
  _corporatedriverLength:number;
  constructor(
    private _CorporateVendorService:CorporateVendorService,

  ) { }

  ngOnInit() {    
    this._CorporateVendorService.getCorporateVendorDrivers().subscribe(dat=>{      
      this._corporatedriverData=dat['data']['corporate_driver'];
      this.image_path=dat['data']['image_path'];   
      this._corporatedriverLength=dat['data']['corporate_driver'].length;
    },
    error => {        
      console.log(error);     
    });
  } 

}

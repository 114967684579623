import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_services/data.service';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css']
})
export class EmailVerifyComponent implements OnInit {
  _status:number;
  _msg:string;
  public spinkit = Spinkit;
  constructor(
    private _Router:Router,
    private _ActivatedRoute:ActivatedRoute,
    private _DataService:DataService,
  ) { }

  ngOnInit() {
    const _code= this._ActivatedRoute.snapshot.params.email_code;
    this._DataService.VerifyEmail(_code).subscribe(res=>{        
      this._status=res['status'];
      this._msg=res['message'];      
    },error => { 
		console.log(error);    
		this._status=0;
		this._msg='Unable to Verify. Because of Internal Server Error,Try again Later';       
    });
  }

} 

import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/_services/data.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { Spinkit } from 'ng-http-loader';
@Component({
  selector: 'app-search-details',
  templateUrl: './search-details.component.html',
  styleUrls: ['./search-details.component.css']
})
export class SearchDetailsComponent implements OnInit {
  public spinkit = Spinkit;
  __showChat= 'off';
  constructor(
    private _Router: Router,
    private _DataService:DataService,
    private _UserService:UserService,
    ) { } 
  slideConfigInbound={};
  slideConfigThumbInbound={};
  slideConfigOutbound={};
  slideConfigThumbOutbound={};
  drverId:any;
  inbound:any;
  outbound:any;
  inboundLength:number =0;
  outboundLength:number =0;
  inboundGallery:any;
  outboundGallery:any;
  _VehiclePath:string;
  total_amount:number;
  bookingData={
    'driver_trip_id_in':0,
    'driver_trip_id_out':0,
    'driver_id_in':0,
    'driver_id_out':0,
    'trip_id_in':0,
    'trip_id_out':0,
    'route_id_in':0,
    'route_id_out':0,
    'user_id':0,
    'amount':0,
  };
  _status:number;
  _msg:string;
  ngOnInit() {
    this.slideConfigInbound = {
      slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      arrows: false, fade: true, asNavFor: '.slider-nav-In'
    };
    this.slideConfigThumbInbound = {
      slidesToShow: 3, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      dots: false, centerMode: true, focusOnSelect: true, asNavFor: '.slider-for-In'
    };

    this.slideConfigOutbound = {
      slidesToShow: 1, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      arrows: false, fade: true, asNavFor: '.slider-nav-Out'
    };
    this.slideConfigThumbOutbound = {
      slidesToShow: 3, slidesToScroll: 1, autoplay: true, autoplaySpeed: 2000, 
      dots: false, centerMode: true, focusOnSelect: true, asNavFor: '.slider-for-Out'
    };
    this.drverId=this._DataService._getDriveId();    
    if(this.drverId){
      this._DataService.getSelectedTripDriverDetails(this.drverId).subscribe(res=>{ 
        this.inboundGallery=res['data']['inbound_vehicle_gallery']; 
        this.outboundGallery=res['data']['outbound_vehicle_gallery']; 
        this._VehiclePath=res['data']['vehicle_path'];           
        this.inbound=res['data']['inbound']; 
        this.outbound=res['data']['outbound']; 
        this.total_amount=res['data']['total_amount'];       
        this.bookingData['driver_trip_id_in']=this.inbound['driver_trip_id'];
        this.bookingData['driver_id_in']=this.inbound['user_id'];
        this.bookingData['trip_id_in']=this.inbound['trip_id'];
        this.bookingData['route_id_in']=this.inbound['route_id'];
        this.bookingData['amount']=this.total_amount;          
        this.inboundLength = Object.keys(res['data']['inbound']).length;
        this.outboundLength = Object.keys(res['data']['outbound']).length;        
        if(this.outbound){
          this.bookingData['driver_trip_id_out']=this.outbound['driver_trip_id'];
          this.bookingData['driver_id_out']=this.outbound['user_id'];
          this.bookingData['trip_id_out']=this.outbound['trip_id'];
          this.bookingData['route_id_out']=this.outbound['route_id'];          
        }          
      },error=>{
        console.log(error);
      });  
    }else{
      this._Router.navigate(['individual']);  
      return;
    }
  }
  bookTrip(){
    this._UserService.bookingTrip(this.bookingData).subscribe(res=>{     
      this._status=res['status'];
      this._msg=res['message'];          
    },error=>{
      console.log(error);
    });
  }
  openChat(show){ 
    this.__showChat = show;
  }
}